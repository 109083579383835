import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable,  of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import {BusinessPayout, Business, BusinessOrder, Product, User} from '../_models';
import {catchError, map} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: `Bearer ${environment.stripePublishableKey}`,
    'Content-Type': 'application/json',
  }).append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'POST')
      .append('Access-Control-Allow-Origin', '*')
};
declare var Stripe: any;
@Injectable()
export class PaymentService {
  stripe = Stripe(environment.stripePublishableKey);

  constructor(public db: AngularFirestore, private http: HttpClient) {}

  getBusinessOrders(): Observable<BusinessOrder[]> {
    return this.db.collection('businessOrders', ref => {
      let query: any = ref;
      query = query.orderBy('charger_at');
      return query;
    }).snapshotChanges().pipe(map((actions: any) => {
      return actions.map((a: any) => {
        const data = a.payload.doc.data() as BusinessOrder;
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  getBusinessOrder(id: string): Observable<BusinessOrder> {
    return this.db.collection('businessOrders').doc(id)
        .snapshotChanges().pipe(
            map(a => {
              const data = a.payload.data() as BusinessOrder;
              data.id = a.payload.id;
              return data;
            }),
            catchError(this.handleError<BusinessOrder>(`getPayment id=${id}`))
        );
  }

  getLuxifyOrder(id: string): Observable<any> {
    return this.db.collection('luxifyOrders').doc(id)
        .snapshotChanges().pipe(
            map(a => {
              const data = a.payload.data() as BusinessOrder;
              data.id = a.payload.id;
              return data;
            }),
            catchError(this.handleError<BusinessOrder>(`getLuxifyOrder id=${id}`))
        );
  }
  getLuxifyOrders(): Observable<any> {
    return this.db.collection('luxifyOrders', ref => {
      let query: any = ref;
      query = query.orderBy('created_at');
      return query;
    }).snapshotChanges().pipe(map((actions: any) => {
      return actions.map((a: any) => {
        const data = a.payload.doc.data() as BusinessOrder;
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }
  createCustomAccount(businessId: string, tokenAccount: string, tokenBankAccount: string,  countryCode: string, identityOwnerFile: string) {
    return new Promise((resolve) => {
      const data: any = {
        businessId,
        tokenAccount,
        tokenBankAccount,
        countryCode,
        identityOwnerFile
      };
      return this.http.post(environment.cloudFunctionUrl + '/createStripeCustomer', data, httpOptions)
          .toPromise()
          .then((res: any) => {
            resolve({success: true, data: res});
          }).catch((error) => {
                if (error && error.error) {
                  resolve({success: false, data: error, message: error.error.message});
                } else {
                  return this.handleError;
                }
              }
          );
    });
  }
  async getAccountLink(stripeCustomID: string, businessId: string) {
    if (!stripeCustomID || !businessId) {
      return {success: true};
    }
    const data: any = {
      stripeCustomID,
      redirectURL: `${environment.appUrl}/business/${businessId}`,
    };
    try {
      return await this.http.post(environment.cloudFunctionUrl + '/getAccountLink', data, httpOptions).toPromise();
    } catch (error) {
      if (error && !error.success) {
        return (error);
      } else {
        return this.handleError;
      }
    }
  }

  processPayment(token: any, amount: number, quantity: number, productID: string = null) {
    return new Promise((resolve) => {
      const data: any = {productID, token, amount, quantity};
      return this.http.post(environment.cloudFunctionUrl + '/stripeCharge', data, httpOptions)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((error) => {
                if (error && error.error) {
                  resolve({success: false, data: error, message: error.error.message});
                } else {
                  return this.handleError;
                }
              }
          );
    });
  }

  processRefund(paymentID: string, reason: string) {
    return new Promise((resolve) => {
      const data: any = {paymentID, reason};
      return this.http.post(environment.cloudFunctionUrl + '/stripeRefunds', data, httpOptions)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          }).catch((error) => {
                if (error && error.error) {
                  resolve({success: false, data: error, message: error.error.message});
                } else {
                  return this.handleError;
                }
              }
          );
    });
  }

  listForBusiness(businessId: string): Observable<Product[]> {
    return this.db.collection('businessOrders', ref => {
      return ref.where('businessId', '==', businessId);
    }).snapshotChanges().pipe(map((actions: any) => {
      return actions.map((a: any) => {
        const data = a.payload.doc.data() as BusinessOrder;
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  async onSubmitCustomAccount(customAccount: any, business: Business) {
    if (!customAccount.currency) {
      return {success : false, message : 'You need to select a currency'};
    } else if (!business.representativeIdentityProof) {
      return {success : false,
        message : 'Error with the representative identity proof file. please update it on the representative details section'};
    } else if (!business.representativeDateOfBirth) {
      return {success : false,
        message : 'Error with the representative date of birth. please update it on the representative details section'};
    }

    const tempAccount: BusinessPayout = {
      type: business.businessType,
      currency: customAccount.currency,
      bankAccountNumber: customAccount.bankAccountNumber,
      bankNumber: customAccount.bankNumber,
      bankAccountName: customAccount.bankAccountName,
      bankName: customAccount.bankName,
      first_name: business.representativeFirstName,
      last_name: business.representativeLastName,
      dateOfBirth: business.representativeDateOfBirth,
      identityProof: business.representativeIdentityProof,
      address: {
        countryCode: business.address.countryCode,
        country: business.address.country,
        line1: business.address.streetAddress,
        city: business.address.city,
        state: business.address.state,
        postal_code: business.address.postcode
      }
    };

    const resultBankAccount = await this.stripe.createToken('bank_account', {
      country: tempAccount.address.countryCode,
      currency: tempAccount.currency,
      account_holder_type: 'company', // account_holder_type : individual or company
      account_number: tempAccount.bankAccountNumber.split(' ').join('-'),
      routing_number: tempAccount.bankNumber.split(' ').join('-'),
      account_holder_name: tempAccount.bankAccountName.trim(),
      bank_name: tempAccount.bankName.trim(),
    });

    if (resultBankAccount.error) {
      return {success : false, message : resultBankAccount.error.message};
    }

    // Create the File from the URL image
    const type = tempAccount.identityProof
        .split('.')[tempAccount.identityProof.split('.').length - 1]
        .split('?')[0]
        .toLowerCase();
    // if we have issue with the cors domain, we need to update gcloud.
    // see : https://firebase.google.com/docs/storage/web/download-files#cors_configuration
    const response = await fetch(tempAccount.identityProof, {mode: 'cors'});
    const dataFile = await response.blob();
    const metadata = {
      type: `image/${type}`
    };
    const idFile = new File([dataFile], `identity-proof.${type}`, metadata);

    // Upload the file to stripe
    const identityData = new FormData();
    // data.append('file', this.idFiles);
    identityData.append('file', idFile);
    identityData.append('purpose', 'identity_document');
    let fileResult = await fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: {Authorization: `Bearer ${environment.stripePublishableKey}`},
      body: identityData,
    });
    const identityFileData = await fileResult.json();

    if (identityFileData.error) {
      return {success : false,
        message : identityFileData.error.message + '. Please update it on your profile on the representative details section.'};
    }
    /* resend the request for the person id */

    fileResult = await fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: {Authorization: `Bearer ${environment.stripePublishableKey}`},
      body: identityData,
    });
    const identityOwnerFileData = await fileResult.json();

    if (identityOwnerFileData.error) {
      return {success : false,
        message : identityOwnerFileData.error.message + '. Please update it on your profile on the representative details section.'};
    }

    const accountData: any = {
      business_type: business.businessType, // 'company',
      tos_shown_and_accepted: true,
      country : tempAccount.address.countryCode,
      default_currency: tempAccount.currency
    };
    if (business.businessType === 'individual') {
      accountData.individual = {
        address: {
          line1: tempAccount.address.line1,
          city: tempAccount.address.city,
          state: tempAccount.address.state,
          postal_code: tempAccount.address.postal_code.toString(),
          country: tempAccount.address.countryCode,
        },
        email : business.representativeEmail,
        phone : business.representativePhone,
        dob: {
          day: tempAccount.dateOfBirth.day,
          month: tempAccount.dateOfBirth.month,
          year: tempAccount.dateOfBirth.year,
        },
        first_name: tempAccount.first_name,
        last_name: tempAccount.last_name,
        verification : {
          document: {
            front: identityFileData.id,
          }
        }
      };
    } else {
      accountData.company = {
        address: {
          line1: tempAccount.address.line1,
          city: tempAccount.address.city,
          state: tempAccount.address.state,
          postal_code: tempAccount.address.postal_code.toString(),
          country: tempAccount.address.countryCode,
        },
        tax_id: business.taxId,
        owners_provided : true,
        directors_provided : true,
        name: business.name,
        phone: business.representativePhone,
        verification : {
          document: {
            front: identityFileData.id,
          }
        }
      };
    }
    // set the new accounts
    const resultAccount = await this.stripe.createToken('account', accountData);

    if (resultAccount.error) {
      return {success : false, message : resultAccount.error.message};
    }
    return this.createCustomAccount(business.id,
        resultAccount.token.id, resultBankAccount.token.id, tempAccount.address.countryCode, identityOwnerFileData.id);
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
