<div class="page-content container-fluid">
    <h1 class="text-center page-title mb-5">User Details</h1>
    <form class="" (ngSubmit)="update()" #updateForm="ngForm">

        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="col-form-label" for="id">Id</label>
                            <input type="text" class="form-control" [(ngModel)]="user.id" name="id" id="id" readonly>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="col-form-label" for="created_at">Created at</label>
                            <input type="text" class="form-control" value="{{user.created_at | timeToLabel}}" name="created_at" id="created_at" readonly>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="col-form-label" for="businessId">businessId</label>
                            <input type="text" class="form-control" [(ngModel)]="user.businessId" name="businessId" id="businessId">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="col-form-label" for="role">Role</label>
                            <select class="form-control" [(ngModel)]="user.role"  name="role" id="role">
                                <option *ngFor="let item of userRoleList" [value]="item"
                                        [selected]="item === user.role">{{item}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="col-form-label" for="firstName">firstName</label>
                            <input type="text" class="form-control" [(ngModel)]="user.firstName" name="firstName" id="firstName">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="col-form-label" for="lastName">lastName</label>
                            <input type="text" class="form-control" [(ngModel)]="user.lastName" name="lastName" id="lastName">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="col-form-label" for="phone">phone</label>
                            <input type="text" class="form-control" [(ngModel)]="user.phone" name="phone" id="phone">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group" [ngClass]="{'is-invalid': !user.dateOfBirth }">
                            <label class="" for="dateOfBirth">Date of birth<span class="required">*</span></label>
                            <input class="form-control form-control-lg" placeholder="DD/MM/YYYY" id="dateOfBirth"
                                   name="dp" [(ngModel)]="user.dateOfBirth" ngbDatepicker #d="ngbDatepicker"
                                   [maxDate]="datePickerOption.maxDate" [minDate]="datePickerOption.minDate"
                                   (click)="d.toggle()" readonly>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row photo-row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="col-form-label mb-3" for="identityProof">Identity proof</label>
                            <app-file-upload *ngIf="user.id && fileUploadFolder" [currentfileurl]="user.identityProof" [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="200"
                                             [extensions]="['png','jpg','jpeg']" (fileDeleted)="deleteIdFilePath('identityProof')" (filepath)="getIdFilePath($event, 'identityProof')" id="identityProof" [max-size]="10000000"></app-file-upload>
                            <p class="text-left form-text text-muted small">The uploaded file needs to be a color image (smaller than 8,000px by 8,000px), in JPG or PNG format, and less than 10 MB in size.</p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="col-form-label mb-3" for="profileImage">Profile image</label>
                            <app-file-upload *ngIf="user.id && fileUploadFolder" [currentfileurl]="user.profileImage" [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="200" [max-size]="5242880"
                                             [extensions]="['png','jpg','jpeg']" (fileDeleted)="deleteIdFilePath('profileImage')" (filepath)="getIdFilePath($event, 'profileImage')" id="profileImage"></app-file-upload>
                            <p class="text-left form-text text-muted small">The file must be JPG, JPEG or PNG and less than 5MB.</p>
                        </div>
                    </div>
                </div>
                <div class="text-center my-5">
                    <button type="submit" class="btn btn-primary" [disabled]="!updateForm.form.valid">UPDATE</button>
                </div>
            </div>
        </div>
    </form>
</div>
