/*PAGES*/

export * from './login/login.component';

export * from './account/account.component';

export * from './products/list/list.component';
export * from './products/details/product.component';
export * from './products/details/details/details.component';
export * from './products/details/characteristics/characteristics.component';
export * from './products/details/medias/medias.component';
export * from './products/details/prices/prices.component';
export * from './products/details/seller/seller.component';

export * from './dashboard/dashboard.component';

export * from './lifestyles/list/list.component';
export * from './lifestyles/details/details.component';

export * from './orders/business/list/list.component';
export * from './orders/business/details/details.component';
export * from './orders/luxify/list/list.component';
export * from './orders/luxify/details/details.component';

export * from './users/list/list.component';
export * from './users/details/details.component';
export * from './admi-users/list/list.component';
export * from './admi-users/details/details.component';

export * from './businesses/list/list.component';
export * from './businesses/details/business.component';
export * from './businesses/details/details/details.component';
export * from './businesses/details/profile/profile.component';
export * from './businesses/details/representative/representative.component';
export * from './businesses/details/bank-details/bank-details.component';
export * from './businesses/details/shipping/shipping.component';
export * from './businesses/details/products/products.component';
