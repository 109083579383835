<div class="page-content container-fluid" *ngIf="business">
    <h1 class="text-center page-title mb-5">Business Details</h1>
    <div class="row justify-content-center">
        <div class="col-lg-9">
            <nav class="nav nav-pills flex-column flex-sm-row">
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.details}" (click)="switchView('details')"><span>1</span>DETAILS</button>
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.profile}" (click)="switchView('profile')" [disabled]="!business.id"><span>2</span>PROFILE</button>
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.representative}" (click)="switchView('representative')" [disabled]="!business.id"><span>3</span>Representative</button>
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.shipping}" (click)="switchView('shipping')" [disabled]="!business.id"><span>3</span>Shipping</button>
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.bankDetails}" (click)="switchView('bankDetails')" [disabled]="!business.id"><span>4</span>Bank details</button>
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.products}" (click)="switchView('products')" [disabled]="!business.id"><span>5</span>Products</button>
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.sources}" (click)="switchView('sources')" [disabled]="!business.id"><span>6</span>Sources</button>
                <button class="flex-sm-fill text-sm-center nav-link text-uppercase" [ngClass]="{'active': show.users}" (click)="switchView('users')" [disabled]="!business.id"><span>7</span>Users</button>
            </nav>
        </div>
    </div>
    <section class="bg-gray-light" *ngIf="!accountLink.isVerified || updateAccountReturn">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="mt-3 text-center" >
                        <div class="alert alert-warning text-left" role="alert" *ngIf="!updateAccountReturn && !accountLink.isVerified && accountLink.url">
                            You need to provide more details to activate your payout :<br/>
                            <small class="small">- <u><b><a [attr.href]="accountLink.url" [href]="accountLink.url">{{accountLink.disabled_reason || 'here'}}</a></b></u></small>
                            <br/>
                            <small class="small">The validation can take few hours, if you already submit the update please wait minimum a day.</small>
                        </div>
                        <div class="alert alert-success" role="alert" *ngIf="updateAccountReturn && updateAccountReturn === 'success'">
                            Your payout has been successfully updated!
                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="updateAccountReturn && updateAccountReturn === 'failure'">
                            An error occurred please try again :<br/>
                            - <a [attr.href]="accountLink.url" [href]="accountLink.url"><u><b>{{accountLink.disabled_reason || 'here'}}</b></u></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-list-business-details *ngIf="show.details" (stepReturn)="stepReturn($event)" [business]="business" [countries]="countries"></app-list-business-details>
    <app-list-business-profile *ngIf="show.profile" (stepReturn)="stepReturn($event)" [business]="business" [fileUploadFolder]="fileUploadFolder"></app-list-business-profile>
    <app-list-business-representative *ngIf="show.representative" (stepReturn)="stepReturn($event)" [fileUploadFolder]="fileUploadFolder" [business]="business"></app-list-business-representative>
    <app-list-business-shipping *ngIf="show.shipping" (stepReturn)="stepReturn($event)" [customAccount]="customAccount" [business]="business"></app-list-business-shipping>
    <app-list-business-bank-details *ngIf="show.bankDetails"  (stepReturn)="stepReturn($event)" [business]="business" [countries]="countries" [currencies]="currencies" [customAccount]="customAccount"></app-list-business-bank-details>
    <app-list-business-products *ngIf="show.products" [business]="business"></app-list-business-products>
    <app-list-business-sources *ngIf="show.sources" [business]="business"></app-list-business-sources>
    <app-list-business-users *ngIf="show.users" [business]="business"></app-list-business-users>
</div>
