import {BrowserModule} from '@angular/platform-browser';
import {NgModule, NgZone, PLATFORM_ID} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import * as $ from 'jquery';
import * as bootstrap from 'bootstrap';
import * as moment from 'moment';

import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CONST_ROUTING} from './app.routing';

import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireStorageModule} from '@angular/fire/storage';

import {environment} from '../environments/environment';

import {AgGridModule} from '@ag-grid-community/angular';
import {Ng2TelInputModule} from './_directives/ng2-tel-input/ng2-tel-input.module';

import {
    LoginComponent,
    AccountComponent,
    DashboardComponent,
    ListProductsComponent,
    ProductCharacteristicsComponent,
    ProductComponent,
    ProductDetailsComponent,
    ProductMediasComponent,
    ProductPricesComponent,
    ListBusinessesComponent,
    BusinessComponent,
    ListBusinessPayoutComponent,
    ListBusinessRepresentativeComponent,
    ListBusinessProfileComponent,
    ListBusinessDetailsComponent,
    ListBusinessShippingComponent,
    UserDetailsComponent,
    ListUsersComponent,
    AdminUserDetailsComponent,
    ListAdminUsersComponent,
    ProductSellerComponent,
    BusinessOrderDetailsComponent,
    ListBusinessOrdersComponent,
    ListLuxifyOrdersComponent,
    LuxifyOrderDetailsComponent,
    LifestyleDetailsComponent, ListLifestyleComponent, BusinessProductsComponent
} from '../pages';

import {
    AppService,
    AlertService,
    AuthenticationService,
    UserService,
    BusinessService,
    ProductService,
    AuthGuardService,
    NotLoginGuardService,
    PaymentService,
    NgbDateCustomParserFormatter,
    SendinblueService,
    AdminService,
    MarketplacePublicFireStorageFactory,
    MarketplacePrivateFireStorageFactory,
    MarketplacePublicFireStorageService,
    MarketplacePrivateFireStorageService
} from './_services';

import {AlertComponent, DropZoneDirective, RouterLinkRendererComponent} from './_directives';

import {NvD3Module} from 'ng2-nvd3';
// d3 and nvd3 should be included somewhere
import 'd3';
import 'nvd3';
import {
    FileUploadComponent,
    FooterComponent,
    LeftMenuComponent,
    LoaderComponent,
    LoaderService,
    MenuComponent,
    PaymentComponent
} from './shared';
import {DurationToTimePipe, FileSizePipe, JsonPrettyPipe, TimeToLabelPipe, TimeUntilNowPipe} from './_pipe';
import {BusinessSourcesComponent} from '../pages/businesses/details/sources/sources.component';
import {BusinessUsersComponent} from '../pages/businesses/details/users/users.component';


@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        LeftMenuComponent,
        FooterComponent,
        LoaderComponent,
        LoginComponent,
        AccountComponent,
        ListProductsComponent,
        ProductCharacteristicsComponent,
        ProductComponent,
        ProductDetailsComponent,
        ProductMediasComponent,
        ProductPricesComponent,
        ProductSellerComponent,
        BusinessComponent,
        AdminUserDetailsComponent,
        ListAdminUsersComponent,
        ListBusinessesComponent,
        ListBusinessPayoutComponent,
        ListBusinessRepresentativeComponent,
        ListBusinessProfileComponent,
        ListBusinessDetailsComponent,
        ListBusinessShippingComponent,
        UserDetailsComponent,
        ListUsersComponent,
        AlertComponent,
        RouterLinkRendererComponent,
        FileUploadComponent,
        FileSizePipe,
        DurationToTimePipe,
        TimeUntilNowPipe,
        JsonPrettyPipe,
        TimeToLabelPipe,
        PaymentComponent,
        DashboardComponent,
        DropZoneDirective,
        BusinessSourcesComponent, BusinessUsersComponent,
        BusinessOrderDetailsComponent, ListBusinessOrdersComponent, ListLuxifyOrdersComponent, LuxifyOrderDetailsComponent,
        LifestyleDetailsComponent, ListLifestyleComponent, BusinessProductsComponent

    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        CONST_ROUTING,
        AngularFireModule.initializeApp(environment.firebase, 'admin'), // Main Angular fire module
        AngularFirestoreModule,  // Firebase database module
        AngularFireAuthModule,
        AngularFireStorageModule,
        NgbModule,
        NvD3Module,
        AgGridModule.withComponents([]),
        Ng2TelInputModule
    ],
    providers: [
        AppService,
        PaymentService,
        AlertService,
        AuthenticationService,
        UserService,
        AdminService,
        BusinessService,
        ProductService,
        LoaderService,
        AuthGuardService,
        NotLoginGuardService,
        SendinblueService,
        {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
        {provide: MarketplacePublicFireStorageService, deps: [PLATFORM_ID, NgZone], useFactory: MarketplacePublicFireStorageFactory},
        {provide: MarketplacePrivateFireStorageService, deps: [PLATFORM_ID, NgZone], useFactory: MarketplacePrivateFireStorageFactory},
    ],
    exports: [
        AppComponent,
        LoaderComponent,
        FileUploadComponent,
        FileSizePipe,
        DurationToTimePipe,
        TimeUntilNowPipe,
        JsonPrettyPipe,
        TimeToLabelPipe,
        PaymentComponent,
        DropZoneDirective
    ],
    bootstrap: [AppComponent],
    entryComponents: [AlertComponent]
})
export class AppModule {
}
