import {
    Component, OnInit
} from '@angular/core';

import {AllCommunityModules, GridApi, IDatasource, IGetRowsParams} from '@ag-grid-community/all-modules';

import {Product} from '../../../app/_models';
import {AdminService, ProductService} from '../../../app/_services';
import {Observable} from 'rxjs';
import {RouterLinkRendererComponent} from '../../../app/_directives';

@Component({
    selector: 'app-list-products',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    entryComponents: [ RouterLinkRendererComponent ]
})

export class ListProductsComponent implements OnInit {
    defaultColDef = { resizable: true ,  sortable: true, filter: true};

    columnDefs = [
        {headerName: 'Id', field: 'id',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/product',
                slug2: 'businessId',
            }},
        {headerName: 'Business Id', field: 'businessId',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/business',
            }},
        {field: 'businessName'},
        {field: 'category'},
        {field: 'brand'},
        {field: 'title'},
        {headerName: 'Price',
            colId: 'productPrice',
            valueGetter: (params) => {
                return params.data.variants[0].price;
            },
            sortable : false,
            filter : false
        },
        {field: 'importType'},
        {field: 'validated'},
        {field: 'published'},
        {field: 'published_at'},
        {field: 'available'},
        {field: 'isRemoved'},
        {field: 'year'},
        {field: 'subCategory'},
        {field: 'model'},
        {field: 'isVintage'},
        {field: 'currency'},
        {field: 'hasAgreeToTerms'},
        {field: 'isShippingFromBusiness'},
        {field: 'created_at'},
        {field: 'updated_at'},
    ];

    modules = AllCommunityModules;
    isFirstLoad = true;
    totalRecords = 0;
    gridApi: GridApi;
    maxSize = 100;
    gridOptions = {
        pagination: true,
        rowModelType: 'infinite',
        cacheBlockSize: this.maxSize, // you can have your custom page size
        paginationPageSize: this.maxSize // pagesize
    };

    currentParams = {orderBy: {}, filter: {}};
    dataSource: IDatasource = {
        getRows: (params: IGetRowsParams) => {
            const tempParams: any = {orderBy: params.sortModel[0] || {}, filter: params.filterModel};
            if ((JSON.stringify(tempParams) !== JSON.stringify(this.currentParams))) {
                this.isFirstLoad = true;
                this.currentParams = tempParams;
                this.totalRecords = 0;
            }
            let dataPromise: Promise<any[]>;
            if (this.isFirstLoad || this.totalRecords === 0) {
                dataPromise = this.adminService.getFirstData('products', this.currentParams, this.maxSize);
                this.isFirstLoad = false;
            } else {
                dataPromise = this.adminService.getNextData();
            }
            dataPromise.then(data => {
                const size = data.length;
                this.totalRecords += size;
                let pagCount = this.totalRecords;
                if (size === this.maxSize) {
                    pagCount += this.maxSize * 2;
                }
                params.successCallback(
                    data,
                    pagCount
                );
            });
        }
    };

    constructor(private adminService: AdminService) {
    }
    ngOnInit() {
    }

    onGridReady(params: any) {
        this.gridApi = params.api;
        // this.gridApi.sizeColumnsToFit();
        this.gridApi.setDatasource(this.dataSource);
    }

}
