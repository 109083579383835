<section class="bg-gray-light" *ngIf="business && business.id">
  <div class="container py-5">
    <form class="" (ngSubmit)="update()" #updateForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <p class="text-center mb-5">
            Tell us how would you like to rate your shipping pricing. The shipping rate are based on the price of your product.
          </p>
          <div class="alert alert-primary" role="alert" *ngIf="!business.shippingRates || business.shippingRates.length<1">
            All your product will have a Free shipping.
          </div>
          <div class="row align-items-end" *ngFor="let rate of business.shippingRates; let i=index">
            <div class="col">
              <div class="form-group">
                <label class="col-form-label" [for]="'min-'+i">Minimum<span class="required">*</span></label>
                <input type="text" class="form-control form-control-lg" [id]="'min-'+i" placeholder="From" [(ngModel)]="rate.min" (change)="onChange()" [name]="'min-' + i" required>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="col-form-label" [for]="'max-'+i">Maximum</label>
                <input type="text" class="form-control form-control-lg" [id]="'max-'+i" placeholder="To" [(ngModel)]="rate.max" (change)="onChange()" [name]="'max-' + i">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="col-form-label" [for]="'price-'+i">Amount</label>
                <div class="input-group">
                  <input type="text" class="form-control form-control-lg" [id]="'price-'+i" placeholder="$" [(ngModel)]="rate.price" (change)="onChange()" [name]="'price-' + i" required>
                  <div class="input-group-append">
                    <span class="input-group-text input-group-text-primary">{{customAccount.currency || '$'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <button type="button" class="btn btn-outline-danger" (click)="removeRate(i)"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button type="button" class="btn btn-outline-primary text-uppercase" (click)="addRate()">Add rate</button>
          </div>

          <div class="row mt-5">
            <div class="col-6 text-left">
              <button type="button" (click)="goBack()" class="btn btn-outline-primary">GO BACK TO STEP 3 </button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!updateForm.form.valid">UPDATE</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
