import {
    Component, Input, OnInit
} from '@angular/core';

import {AllCommunityModules} from '@ag-grid-community/all-modules';

import {Business, BusinessSource, Product} from '../../../../app/_models';
import {BusinessService, ProductService} from '../../../../app/_services';
import {Observable} from 'rxjs';
import {RouterLinkRendererComponent} from '../../../../app/_directives';

@Component({
    selector: 'app-list-business-sources',
    templateUrl: './sources.component.html',
    styleUrls: ['./sources.component.scss'],
    entryComponents: [ RouterLinkRendererComponent ]
})

export class BusinessSourcesComponent implements OnInit {
    defaultColDef = { resizable: true ,  sortable: true, filter: true};
    @Input('business') business: Business = {};


    columnDefs = this.business.isAffiliate ? [
        {field: 'id'},
        {field: 'programName'},
        {field: 'catalogName'},
        {field: 'totalProduct'},
        {field: 'totalValue'},
        {field: 'totalStocks'},
        {field: 'path'},
        {field: 'zipPath'},
        {field: 'created_at'},
        {field: 'updated_at'},
    ] : [
        {field: 'id'},
        {field: 'numberOfProducts'},
        {field: 'valueOfProducts'},
        {field: 'programName'},
        {field: 'catalogName'},
        {field: 'numberOfProducts'},
        {field: 'zipPath'},
        {field: 'fileName'},
        {field: 'created_at'},
        {field: 'updated_at'},
    ];

modules = AllCommunityModules;
rowData: Observable<BusinessSource[]>;

constructor(private businessService: BusinessService) {
}

ngOnInit() {
    if (this.business && this.business.id) {
        this.rowData = this.businessService.listSource(this.business.id);
    }
}

}
