<div class="page-content container-fluid">
    <div *ngIf="business.isAffiliate">
        <h4>Summary :</h4>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="col-form-label" for="numberOfProducts">Number of products</label>
                    <input type="text" class="form-control form-control-lg" id="numberOfProducts" placeholder="0" [value]="business.numberOfProducts" readonly>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="col-form-label" for="valueOfProducts">Value of products</label>
                    <input type="text" class="form-control form-control-lg" id="valueOfProducts" placeholder="0" [value]="business.valueOfProducts" readonly>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="col-form-label" for="productsCurrency">Currency</label>
                    <input type="text" class="form-control form-control-lg" id="productsCurrency" placeholder="0" [value]="business.productsCurrency" readonly>
                </div>
            </div>
        </div>
    </div>
    <p class="text-center small primary-color">Please note that the data can be sort or filter but not both at the same time</p>
    <ag-grid-angular
            class="ag-theme-balham w-100 mb-5"
            [rowData]="rowData | async"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
            [modules]="modules"
            [floatingFilter]="true">
    </ag-grid-angular>
</div>
