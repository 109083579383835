import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AdminService} from './admin.service';
import {auth} from 'firebase/app';
import {User} from '../_models';
import {BusinessService} from './business.service';
import * as moment from 'moment';
import {AngularFirestore} from '@angular/fire/firestore';
@Injectable()
export class AuthenticationService {
    constructor(public afAuth: AngularFireAuth,
                private adminService: AdminService,
                private businessService: BusinessService,
                private db: AngularFirestore) {
    }

    login(email: string, password: string) {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.signInWithEmailAndPassword(email, password)
                .then((authUser: any) => {
                    if (authUser.user && authUser.user.emailVerified) {
                        return this.pullUserData(authUser.user.uid).then((userData: any) => {
                            localStorage.setItem('currentUser', JSON.stringify(userData.user));
                            resolve(userData.user);
                        });
                    } else {
                        reject({
                            code: 'auth/email-not-verified',
                            message: 'Please, validate your email first.'
                        });
                    }
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    loginGoogleUser() {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
                .then((credential: any) => {
                    const userResult = credential.user;
                    const emailArray = userResult.email.split('@');
                    const isLuxifyUser = emailArray[emailArray.length - 1] === 'luxify.com';
                    if (!isLuxifyUser) {
                        reject('Only Luxify email can connect via Google');
                        return -1;
                    }
                    const user: any = {
                        id: userResult.uid,
                        email: userResult.email,
                        created_at: moment().format()
                    };
                    if (userResult.displayName) {
                        user.firstName = (userResult.displayName.split(' '))[0];
                        if ((userResult.displayName.split(' ')).length > 1) {
                            user.lastName = (userResult.displayName.split(' '))[1];
                        }
                    }
                    const adminUsersCollection = this.db.collection<User>('admin-users');
                    adminUsersCollection.doc(user.id).get()
                        .subscribe(() => {
                            return this.pullUserData(user.id).then((userData: any) => {
                                localStorage.setItem('currentUser', JSON.stringify(userData.user));
                                resolve(userData.user);
                            });
                        }, ( error ) => {
                            return this.db.collection<User>('users').doc(user.id).set(user, {merge: true})
                                .then(() => {reject(`You don't have the right to access to the Admin. Please contact us!` ); })
                                .catch(() => {reject(`You don't have the right to access to the Admin. Please contact us!` ); });
                        } );
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    logout() {
        return new Promise((resolve: any, reject: any) => {
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            this.afAuth.auth.signOut().then((res) => {
                resolve(res);
            }).catch(error => {
                reject(error);
            });
        });
    }

    isLogin() {
        return new Promise((resolve) => {
            this.afAuth.authState.subscribe((authUser) => {
                // resolve(authUser !== null ? authUser.uid : null);
                resolve(authUser !== null ? {id: authUser.uid, email: authUser.email} : {id: null, email: null});
            });
        });
    }

    pullUserData(userId: string) {
        return new Promise((resolve) => {
            this.adminService.get(userId)
                .subscribe((userData) => {
                    resolve({user: userData});
                });
        });
    }

    forgotPassword(email: string) {
        return this.afAuth.auth.sendPasswordResetEmail(email);
    }

    sendEmailVerification(email) {
        // this.af.auth.getAuth().auth.sendEmailVerification();
        return new Promise((resolve, reject) => {
            const currentUser = this.afAuth.auth.currentUser;
            if (currentUser.email === email && !currentUser.emailVerified) {
                this.afAuth.auth.currentUser.sendEmailVerification()
                    .then(() => {
                        resolve(currentUser);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject({message: (currentUser.emailVerified ? 'Your email has been already validated' : 'Email no validated')});
            }
        });
    }
}
