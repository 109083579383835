import {
    Component,
} from '@angular/core';

import {UserService} from '../../../app/_services';
import {User} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-user',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],

})
export class UserDetailsComponent {
    user: User = {};
    isLoading = false;
    fileUploadFolder: string;
    userRoleList: string[] = ['owner', 'admin', 'accountant', 'marketer', 'developer', 'analyst'];
    datePickerOption = {
        maxDate : {year: (new Date().getFullYear()) - 17, month: 12, day: 31},
        minDate : {year: 1900, month: 1, day: 1}
    };
    constructor(private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private userService: UserService) {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.fileUploadFolder = `images/users/${params.id}`;
                this.userService.get(params.id).subscribe((res: User) => {
                    if (!res) {
                        this.loaderService.hide();
                        return this.router.navigate(['404']).then();
                    }
                    this.user = res;
                    this.loaderService.hide();
                }, () => {
                    this.router.navigate(['404']).then();
                    this.loaderService.hide();
                });
            } else {
                this.router.navigate(['404']).then();
                this.loaderService.hide();
            }
        });
    }

    update() {
        this.isLoading = true;
        this.loaderService.show();
        this.userService.update(this.user)
            .then(() => {
                this.isLoading = false;
                this.loaderService.hide();
            });
    }

    getIdFilePath(filePath: { absUrl: string, path: string }, field) {
        this.isLoading = true;
        this.loaderService.show();
        this.user[field] = {src: filePath.absUrl, path: filePath.path};
        this.userService.updateField(this.user, field, this.user[field]).then(() => {
            this.isLoading = false;
            this.loaderService.hide();
        });
    }

    deleteIdFilePath(field) {
        this.isLoading = true;
        this.loaderService.show();
        this.user[field] = null;
        this.userService
            .updateField(this.user, field, null)
            .then(() => {
                this.isLoading = false;
                this.loaderService.hide();
            });
    }
}
