import {
    Component, OnInit
} from '@angular/core';

import {AllCommunityModules, GridApi, IDatasource, IGetRowsParams} from '@ag-grid-community/all-modules';

import {Business} from '../../../app/_models';
import {AdminService, BusinessService, PaymentService} from '../../../app/_services';
import {Observable} from 'rxjs';
import {RouterLinkRendererComponent} from '../../../app/_directives';
import {TimeToLabelPipe} from '../../../app/_pipe';

@Component({
    selector: 'app-list-businesses',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    entryComponents: [ RouterLinkRendererComponent ],
    providers: [ TimeToLabelPipe ]
})

export class ListBusinessesComponent implements OnInit {

    defaultColDef = { resizable: true ,  sortable: true, filter: true};


    columnDefs = [
        {headerName: 'Id', field: 'id', width: 80,
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/business',
            }},
        {field: 'businessType', width: 100},
        {field: 'name', width: 90},
        {field: 'taxId', width: 80},
        {field: 'hasPaymentDetails', width: 160},
        {field: 'hasAgreeToTerms', width: 155},
        {field: 'representativeFirstName'},
        {field: 'representativeLastName'},
        {field: 'representativeEmail'},
        {field: 'representativePhone', width: 165},
        {field: 'created_at', valueFormatter: this.timeToLabel.transform},
        {field: 'country', width: 90 ,
            valueGetter: (params) => {
                return params.data.address ? params.data.address.country : null;
            }},
        {field: 'city', width: 90,
            valueGetter: (params) => {
                return params.data.address ? params.data.address.city : null;
            }},

    ];

    modules = AllCommunityModules;
    isFirstLoad = true;
    totalRecords = 0;
    gridApi: GridApi;
    maxSize = 100;
    gridOptions = {
        pagination: true,
        rowModelType: 'infinite',
        cacheBlockSize: this.maxSize, // you can have your custom page size
        paginationPageSize: this.maxSize // pagesize
    };

    currentParams = {orderBy: {}, filter: {}};
    dataSource: IDatasource = {
        getRows: (params: IGetRowsParams) => {
            const tempParams: any = {orderBy: params.sortModel[0] || {}, filter: params.filterModel};
            if ((JSON.stringify(tempParams) !== JSON.stringify(this.currentParams))) {
                this.isFirstLoad = true;
                this.currentParams = tempParams;
                this.totalRecords = 0;
            }
            let dataPromise: Promise<any[]>;
            if (this.isFirstLoad || this.totalRecords === 0) {
                dataPromise = this.adminService.getFirstData('businesses', this.currentParams, this.maxSize);
                this.isFirstLoad = false;
            } else {
                dataPromise = this.adminService.getNextData();
            }
            dataPromise.then(data => {
                const size = data.length;
                this.totalRecords += size;
                let pagCount = this.totalRecords;
                if (size === this.maxSize) {
                    pagCount += this.maxSize * 2;
                }
                params.successCallback(
                    data,
                    pagCount
                );
            });
        }
    };

    constructor(private adminService: AdminService, private timeToLabel: TimeToLabelPipe) {
    }
    ngOnInit() {
    }

    onGridReady(params: any) {
        this.gridApi = params.api;
        // this.gridApi.sizeColumnsToFit();
        this.gridApi.setDatasource(this.dataSource);
    }

}
