import {
    Component, ComponentFactoryResolver, ViewChild, ViewContainerRef,
} from '@angular/core';

import {AdminService, AlertService} from '../../../app/_services';
import {User} from '../../../app/_models';
import {LoaderService} from '../../../app/shared';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertComponent} from '../../../app/_directives';

@Component({
    selector: 'app-admin-user',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],

})
export class AdminUserDetailsComponent {
    user: User = {};
    isLoading = false;
    fileUploadFolder: string;
    userRoleList: string[] = ['owner', 'admin', 'accountant', 'marketer', 'developer', 'analyst'];
    @ViewChild('alertMessage', {read: ViewContainerRef}) alertMessage: ViewContainerRef;

    constructor(private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private cfr: ComponentFactoryResolver,
                private alertService: AlertService,
                private adminService: AdminService) {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.fileUploadFolder = `images/admins/users/${params.id}`;
                this.adminService.get(params.id).subscribe((res: User) => {
                    if (!res) {
                        this.loaderService.hide();
                        return this.router.navigate(['404']).then();
                    }
                    this.user = res;
                    this.loaderService.hide();
                }, () => {
                    this.router.navigate(['404']).then();
                    this.loaderService.hide();
                });
            } else {
                this.loaderService.hide();
            }
        });
    }

    update() {
        this.isLoading = true;
        this.loaderService.show();
        this.adminService.setAdmin(this.user.id, this.user.email, this.user.role)
            .then(() => {
                this.showAlert('alertMessage');
                this.alertService.success('Updated');
                this.isLoading = false;
                this.loaderService.hide();
            })
            .catch((error: any) => {
                this.showAlert('alertMessage');
                this.alertService.error(error.message || 'Error...');
                this.isLoading = false;
                this.loaderService.hide();
        });
    }

    showAlert(target) {
        this[target].clear();
        const factory = this.cfr.resolveComponentFactory(AlertComponent);
        const ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
}
