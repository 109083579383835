<div class="page-content container-fluid">
    <h1 class="text-center page-title mb-5">Admin User Details</h1>
    <form class="" (ngSubmit)="update()" #updateForm="ngForm">

        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label class="col-form-label" for="email">Email</label>
                            <div class="input-group">
                                <input type="email" class="form-control" [(ngModel)]="user.email" name="email" id="email" [readOnly]="user.id" required>
                                <div class="input-group-append" *ngIf="user.id">
                                    <span class="input-group-text input-group-text-primary"><a [routerLink]="'/user/' + user.id" target="_blank"><i class="fas fa-external-link-alt"></i></a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="col-form-label" for="role">Role</label>
                            <select class="form-control" [(ngModel)]="user.role"  name="role" id="role" required>
                                <option *ngFor="let item of userRoleList" [value]="item"
                                        [selected]="item === user.role">{{item}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col" *ngIf="user.id">
                        <div class="form-group">
                            <label class="col-form-label" for="created_at">Created at</label>
                            <input type="text" class="form-control" value="{{user.created_at | timeToLabel}}" name="created_at" id="created_at" readonly>
                        </div>
                    </div>
                </div>
                <ng-template #alertMessage></ng-template>
                <div class="text-center my-5">
                    <button type="submit" class="btn btn-primary" [disabled]="!updateForm.form.valid">{{user.id ? 'UPDATE' : 'CREATE'}}</button>
                </div>
            </div>
        </div>
    </form>
</div>
