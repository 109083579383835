// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    cloudFunctionUrl: 'https://us-central1-staging-marketplace-a2a46.cloudfunctions.net',
    firebase: {
        apiKey: 'AIzaSyDtvt7toPg1mCWuhbBdTFNYzap3KveetQ0',
        authDomain: 'staging-marketplace-a2a46.firebaseapp.com',
        databaseURL: 'https://staging-marketplace-a2a46.firebaseio.com',
        projectId: 'staging-marketplace-a2a46',
        storageBucket: 'staging-marketplace-a2a46.appspot.com',
        messagingSenderId: '494452896324',
        appId: '1:494452896324:web:bcfb18041f9df7c6b9c211',
        measurementId: 'G-DZYL1KYFZG'
    },
    privateStorageBucket: 'staging-marketplace-a2a46-private',
    stripePublishableKey: 'pk_test_AwmGZaoDAZswG839ci5PgQja00HukxJ9pw',
    appUrl : 'https://admin-staging.luxify.com',
    shopifyAdminUrl: 'https://staging-luxify.myshopify.com/admin',
    getFeeAmountCharge,
    getAmountWithChargeFee
};

function getFeeAmountCharge(amount: number) {
    if (amount > 12000) {
        return 2400;
    }
    const averageFeeToTransferForUs =
        amount < 240 ? 24 : (amount < 3200 ? 25 : (amount < 6400 ? 23 : (amount < 8000 ? 22 : (amount < 12000 ? 20 : 0))));
    return (amount * (averageFeeToTransferForUs / 100));
}

function getAmountWithChargeFee(amount: number) {
    return amount - getFeeAmountCharge(amount);
}
