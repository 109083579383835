<section class="page-content container-fluid">
  <div class="page-header">
    <h1 class="page-title">Overview</h1>
  </div>
  <div class="row my-3 justify-content-center">
    <div class="col-md-4 col-lg-3 col-xs-6 row-eq-height info-panel mt-3">
      <div class="card w-100">
        <div class=" text-center card-body">
          <p class="small gray-color">Products listed this month</p>
          <div class="content-text text-center ">
            <h2><a [routerLink]="['/products']" title="See Product List">{{products.thisMonthlyCount}}</a></h2>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-lg-3 col-xs-6 row-eq-height info-panel mt-3">
      <div class="card w-100">
        <div class=" text-center card-body">
          <p class="small gray-color">New Individuals this month</p>
          <div class="content-text text-center ">
            <h2><a [routerLink]="['/businesses']"
                   title="See Operators List">{{individuals.thisMonthlyCount}}</a></h2>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-lg-3 col-xs-6 row-eq-height info-panel mt-3">
      <div class="card w-100">
        <div class=" text-center card-body">
          <p class="small gray-color">New Businesses this month</p>
          <div class="content-text text-center ">
            <h2><a [routerLink]="['/businesses']"
                   title="See Operators List">{{businesses.thisMonthlyCount}}</a></h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-lg-3 col-xs-6 row-eq-height info-panel mt-3">
      <div class="card w-100">
        <div class=" text-center card-body">
          <p class="small gray-color">Business Orders</p>
          <div class="content-text text-center ">
            <h2><a [routerLink]="['/business-orders']"
                   title="See Booking List">{{payments.thisMonthlyCount}}</a></h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="info-panel my-5">
    <div class="text-center padding-top-10">
      <h3 class=" blue-grey-700 font-size-14">Products</h3>
      <div class=" bg-white">
        <nvd3 [options]="options"
              [data]="products.data">
        </nvd3>
      </div>
    </div>
  </div>

  <div class="info-panel my-5">
    <div class="  text-center padding-top-10">
      <h3 class=" blue-grey-700 font-size-14">Individual</h3>
      <div class=" bg-white">
        <nvd3 [options]="options"
              [data]="individuals.data">
        </nvd3>
      </div>
    </div>
  </div>

  <div class="info-panel my-5">
    <div class="  text-center padding-top-10">
      <h3 class=" blue-grey-700 font-size-14">Businesses</h3>
      <div class=" bg-white">
        <nvd3 [options]="options"
              [data]="businesses.data">
        </nvd3>
      </div>
    </div>
  </div>

  <div class="info-panel my-5">
    <div class="  text-center padding-top-10">
      <h3 class=" blue-grey-700 font-size-14">Business Orders</h3>
      <div class=" bg-white">
        <nvd3 [options]="options"
              [data]="payments.data">
        </nvd3>
      </div>
    </div>
  </div>
</section>
