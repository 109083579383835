import {
  Component,
  OnInit,
} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {User} from './_models';
declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: User;
  constructor(private router: Router) {
    const localData = localStorage.getItem('currentUser');
    this.user = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
