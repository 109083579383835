import {Component, ComponentFactoryResolver, ViewChild, ViewContainerRef} from '@angular/core';
import {Business, User} from '../../_models';
import {NavigationEnd, Router} from '@angular/router';
import {LoaderService} from '../loader/loader.service';
import {AlertService, SendinblueService} from '../../_services';
import {AlertComponent} from '../../_directives';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})


export class FooterComponent {
    currentUser: User = {id: null};
    business: Business = {};
    hideFooter = false;
    isLoading = false;
    userEmail: string;
    @ViewChild('alertMessage', {read: ViewContainerRef}) alertMessage: ViewContainerRef;

    constructor(private router: Router,
                private loaderService: LoaderService,
                private cfr: ComponentFactoryResolver,
                private alertService: AlertService,
                private emailSIBService: SendinblueService) {
        const localData = localStorage.getItem('currentUser');
        this.currentUser = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
        this.userEmail = this.currentUser.email;
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            if (evt.url.indexOf('/login') === 0) {
                this.hideFooter = true;
            } else {
                this.hideFooter = false;
            }
        });
    }

    subscribe() {
        this.isLoading = true;
        this.loaderService.show();
        this.emailSIBService.addUserTolist('early-bird', this.userEmail).then((res: any) => {
            if (res.code === 'already-in-list' || res.code === 'update-contact' || res.code === 'create-contact') {
                this.showAlert('alertMessage');
                this.alertService.success(res.message);
            } else {
                this.showAlert('alertMessage');
                this.alertService.error(res.message || 'Error...');
            }
            this.loaderService.hide();
            this.isLoading = false;
        });
    }

    showAlert(target) {
        this[target].clear();
        const factory = this.cfr.resolveComponentFactory(AlertComponent);
        const ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }

}
