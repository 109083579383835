<div class="page-content container-fluid">
    <div class="text-right mb-3"><a [routerLink]="'/product/'+ business.id" class="btn btn-primary">Add Product</a></div>
    <p class="text-center small primary-color">Please note that the data can be sort or filter but not both at the same time</p>
    <ag-grid-angular
            class="ag-theme-balham w-100 mb-5"
            [rowData]="rowData | async"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
            [modules]="modules"
            [floatingFilter]="true">
    </ag-grid-angular>
</div>
