import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { Observable} from 'rxjs';
import {MarketplacePrivateFireStorageService, MarketplacePublicFireStorageService} from '../../_services';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnInit, OnChanges {
  @Input('is-public') isPublicStorage: boolean;
  @Input('folder') folder: string;
  @Input('extensions') allowedExtensions: string[];
  @Input('max-size') maxAllowedSize: number; // in bytes
  @Input('hide-preview') hidePreview: boolean;
  @Input('currentfileurl') currentFileUrl: string;
  @Input('image-position') imagePosition: string;
  @Input('media-type') mediaType: string;
  @Input('image-class') imageClass: string;
  @Input('view-height') viewHeight: number;
  @Input('template') template: string;
  @Input('input-class') inputClass: string;
  @Output() filepath: EventEmitter<{absUrl: string, path: string}> = new EventEmitter<{absUrl: string, path: string}>();
  @Output() fileDeleted: EventEmitter<void> = new EventEmitter<void>();

  // Progress monitoring
  percentage: Observable<number>;
  snapshot: Observable<any>;
  // Download URL
  completeFileUrl: string;
  // State for dropzone CSS toggling
  isHovering: boolean;
  errorMsg: string;

  private downloadAttempts = 0;

  constructor(private publicStorage: MarketplacePublicFireStorageService,
              private privateStorage: MarketplacePrivateFireStorageService) {
  }

  ngOnInit() {
    if (this.currentFileUrl) {
      this.completeFileUrl = this.currentFileUrl;
    }
    if (!this.template) {
      this.template = 'drop-zone';
    }
    if (!this.mediaType) {
      this.mediaType = 'image';
    }
    if (!this.imageClass) {
      this.imageClass = null;
    }
    if (!this.maxAllowedSize) {
      this.maxAllowedSize = 5242880;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentFileUrl && !changes.currentFileUrl.firstChange) {
      this.completeFileUrl = changes.currentFileUrl.currentValue;
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {
    this.errorMsg = null;
    // The File object
    const file = event.item(0);
    const extension = file.name.split('.').pop().toLowerCase();
    // Client-side validation example
    if (!this.allowedExtensions || this.allowedExtensions.indexOf(extension) === -1) {
      this.errorMsg = 'File type not supported. Allowed file extensions: ' + this.allowedExtensions.join(', ') + ' .';
      return;
    }

    if (this.maxAllowedSize && this.maxAllowedSize < file.size) {
      this.errorMsg = 'File type not supported. Allowed file extensions: ' + this.allowedExtensions.join(', ') + ' .';
      return;
    }

    this.completeFileUrl = null;

    // The storage path
    const newFileName = `${new Date().getTime()}.${extension}`;
    const path = this.folder + '/' + newFileName;

    // The main task
    const storage = this.isPublicStorage ? this.publicStorage : this.privateStorage;

    const task = storage.upload(path, file);
    // Progress monitoring
    this.percentage = task.percentageChanges();
    this.snapshot = task.snapshotChanges();
    const self = this;
    this.percentage.subscribe(perc => {
      if (perc >= 100) {
        // The file's download URL
        this.getDownloadURL(path, self);
      }
    });
  }

  private getDownloadURL(path: string, self: FileUploadComponent) {
    self.downloadAttempts++;
    setTimeout(() => {
      const storage = self.isPublicStorage ? self.publicStorage : self.privateStorage;
      storage.ref(path).getDownloadURL()
          .subscribe(
              absUrl => {
                if (absUrl) {
                  // self.completeFileUrl = absUrl;
                  self.percentage = null;
                  self.snapshot = null;
                  self.filepath.emit({absUrl, path});
                } else if (self.downloadAttempts < 5) {
                  self.getDownloadURL(path, self);
                } else {
                  self.errorMsg = 'Failed to upload your file please try with another one.';
                }
              },
              (error: any) => {
                if (self.downloadAttempts < 5) {
                  self.getDownloadURL(path, self);
                } else {
                  self.errorMsg = error.message || 'Failed to upload your file please try with another one.';
                }

              });
    }, 1000);
  }

  // Determines if the upload task is active
  /* isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  } */

  delete(downloadUrl) {
    const storage = this.isPublicStorage ? this.publicStorage : this.privateStorage;
    const filePath =  downloadUrl.split('.storage.googleapis.com');
    const fileRef = downloadUrl.indexOf('https://firebasestorage.googleapis.com/') === 0
        ? storage.storage.refFromURL(downloadUrl)
        : storage.storage.ref(filePath[filePath.length - 1]);
    fileRef.delete()
        .then(() => {
          this.currentFileUrl = null;
          this.fileDeleted.emit();
        })
        .catch(() => {
          this.currentFileUrl = null;
          this.fileDeleted.emit();
        });
  }
}
