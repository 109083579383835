import {
    Component,
} from '@angular/core';

import {AppService, BusinessService, PaymentService} from '../../../app/_services';
import {BusinessPayout, Business} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-business',
    templateUrl: './business.component.html',
    styleUrls: ['./business.component.scss'],

})
export class BusinessComponent {
    business: Business = {};
    customAccount: BusinessPayout = {address: {country: null}};

    show = {
        details: false,
        profile: false,
        representative: false,
        shipping: false,
        bankDetails: false,
        products: false,
        sources: false,
        users: false,
    };

    fileUploadFolder: string;
    firstLoad = true;
    countries: any[] = this.appService.countryList();
    currencies: any[] = this.appService.currenciesList();

    accountLink: {
        isVerified?: boolean,
        url?: string,
        disabled_reason?: string
    } = {};
    updateAccountReturn: string;

    constructor(private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private appService: AppService,
                private paymentService: PaymentService,
                private businessService: BusinessService) {

        this.getBusiness();
    }

    getBusiness() {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.updateAccountReturn = params.status;
                this.businessService.get(params.id).subscribe((res: Business) => {
                    if (!res) {
                        this.loaderService.hide();
                        return this.router.navigate(['404']).then();
                    }
                    this.fileUploadFolder = `images/businesses/${res.id}/identity`;
                    if (this.firstLoad) {
                        if (!res.address) {
                            res.address = {};
                        }
                        this.business = res;
                        this.firstLoad = false;
                        this.getPaymentDetails(this.business);
                    } else {
                        this.business = res;
                        this.loaderService.hide();
                    }
                }, () => {
                    this.router.navigate(['404']).then();
                    this.loaderService.hide();
                });
            } else {
                this.router.navigate(['404']).then();
                this.loaderService.hide();
            }
        });
    }

    getPaymentDetails(business: Business) {
        this.businessService.getPayoutDetails(business.id).subscribe((bankDetails: BusinessPayout) => {
            this.customAccount = bankDetails || {};
            if (!bankDetails || !bankDetails.bankAccountName) {
                this.customAccount.bankAccountName = business.representativeFirstName + ' ' + business.representativeLastName;
            }
            if (bankDetails && bankDetails.stripeCustomID) {
                this.getAccountLink(bankDetails.stripeCustomID, business.id);
            } else {
                this.switchView('details');
                this.loaderService.hide();
            }
        });
    }

    getAccountLink(stripeCustomID: string, businessId: string) {
        this.paymentService.getAccountLink(stripeCustomID, businessId)
            .then((accountLink: any) => {
                if (accountLink.success && accountLink.data) {
                    this.accountLink = accountLink.data;
                }
                this.switchView('details');
                this.loaderService.hide();
            });
    }

    switchView(step) {
        this.show = {
            details: false,
            profile: false,
            representative: false,
            shipping: false,
            bankDetails: false,
            products: false,
            sources: false,
            users: false,
        };
        this.show[step] = true;
    }

    stepReturn(result: { step: string, business: Business }) {
        if (result.business) {
            this.business = result.business;
        }
        if (result.step) {
            this.switchView(result.step);
        }
        window.scrollTo(0, 0);
        this.loaderService.hide();
    }
}
