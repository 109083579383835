import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';

import {HttpClient, HttpHeaders} from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }).append('Access-Control-Allow-Headers', 'Content-Type')
    .append('Access-Control-Allow-Methods', 'GET')
    .append('Access-Control-Allow-Origin', '*')
};

@Injectable()
export class SendinblueService {
  constructor(private http: HttpClient) {
  }

  addUserTolist(listName: string, email: string) {
    const data: any = {
      listName,
      email
    };
    return this.http.post('https://us-central1-luxify-e62c4.cloudfunctions.net/addUserTolist', JSON.stringify(data), httpOptions)
      .toPromise().catch(this.handleError);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
