/**
 * @Usage : <tr *ngFor='let obj of objects | orderBy : ObjFieldName: OrderByType'>
 * @Param : ObjFieldName: object field name you want to sort;
 * @Param OrderByType: boolean; true: descending order; false: ascending;
 */
import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'jsonPretty'
})
export class JsonPrettyPipe implements PipeTransform {

  transform(val) {
    return JSON.stringify(val, undefined, 4)
        .replace(/ /g, '&nbsp;')
        .replace(/\n/g, '<br/>');
  }

}
