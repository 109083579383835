<div class="page-content container-fluid">
    <h1 class="text-center page-title mb-5">Lifestyle Details</h1>
    <div class="row justify-content-center">
        <div class="col-lg-9">
            <div class="alert alert-primary my-3" role="alert">
                <pre [innerHTML]="order | jsonPretty"></pre>
            </div>
        </div>
    </div>
</div>
