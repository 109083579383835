import {
    Component,
} from '@angular/core';

import {AdminService, PaymentService} from '../../../app/_services';
import {LoaderService} from '../../../app/shared';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-lifestyle',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],

})
export class LifestyleDetailsComponent {
    order: any = {};
    isLoading = false;

    constructor(private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private adminService: AdminService) {


        this.getLifestyle();
    }

    getLifestyle() {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.adminService.getLifestyle(params.id).subscribe((res: any) => {
                    if (!res) {
                        this.loaderService.hide();
                        return this.router.navigate(['404']).then();
                    }
                    this.order = res;
                    this.loaderService.hide();
                }, () => {
                    this.router.navigate(['404']).then();
                    this.loaderService.hide();
                });
            } else {
                this.router.navigate(['404']).then();
                this.loaderService.hide();
            }
        });
    }
}
