import {
    Component, ComponentFactoryResolver, ViewChild, ViewContainerRef
} from '@angular/core';

import {AlertService, AuthenticationService} from '../../app/_services';
import {User} from '../../app/_models';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertComponent} from '../../app/_directives';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],

})
export class AccountComponent {
    user: User = {};
    isLoading = false;
    edit = {identification: false};

    @ViewChild('alertMessage', {read: ViewContainerRef}) alertMessage: ViewContainerRef;


    constructor(private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthenticationService,
                private cfr: ComponentFactoryResolver,
                private alertService: AlertService) {

        const localData = localStorage.getItem('currentUser');
        this.user = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
        if (!this.user.id) {
            this.router.navigate(['/']).then();
            return;
        }
    }

    resetPassword() {
        this.isLoading = true;
        this.authService.forgotPassword(this.user.email).then(
            () => {
                this.showAlert('alertMessage');
                this.alertService.success(
                    'Password recovery instruction has been sent to your email.',
                    true);
                this.isLoading = false;
            },
            error => {
                this.showAlert('alertMessage');
                this.alertService.error(error);
                this.isLoading = false;
            });
    }

    editBlock(block) {
        this.edit = {identification: false};
        this.edit[block] = true;
    }

    showAlert(target) {
        this[target].clear();
        const factory = this.cfr.resolveComponentFactory(AlertComponent);
        const ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
}
