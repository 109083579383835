import {
  Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';

import {ProductService} from '../../../../app/_services';
import {Country, Product, User, Variant} from '../../../../app/_models';
import {LoaderService} from '../../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-product-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss'],

})
export class ProductSellerComponent  implements OnInit {
  @Input('product') product: Product = {};
  @Input('variant') variant: Variant = {};
  @Input('countries') countries: Country[] = [];
  @Output() stepReturn: EventEmitter<{ step: string, product: Product }> = new EventEmitter<{ step: string, product: Product }>();

  selectedCounty: Country;

  needToUpdate = false;
  isLoading = false;
  showTerms = false;
  user: User = {};


  constructor(private loaderService: LoaderService,
              private productService: ProductService) {
  }

  ngOnInit() {
    const localData = localStorage.getItem('currentUser');
    this.user = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
    if (!this.product.shippingFromAddress) {
      this.product.shippingFromAddress = {country: null};
      this.selectedCounty = this.countries[0];
    } else {
      this.selectedCounty = this.countries.find(x => x.alpha2 === this.product.shippingFromAddress.countryCode);
    }
  }

  agreeToTerms() {
    this.needToUpdate = true;
    this.product.hasAgreeToTerms = true;
    $('#SignUpModal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    return this.updateProduct();
  }

  goBack() {
    return this.stepReturn.emit({step: 'prices', product : null});
  }

  onChange() {
    this.needToUpdate = true;
  }

  selectCountry(country) {
    this.selectedCounty = country;
    this.product.shippingFromAddress.country = country.name;
    this.product.shippingFromAddress.countryCode = country.alpha2;
    this.needToUpdate = true;
  }

  updateProduct() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'thanks', product : null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.product.variants = [this.variant];
    this.productService.update(this.product)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
        return this.stepReturn.emit({step: 'thanks', product : this.product});
      });
  }
}
