<div class="page-content container-fluid">
    <h1 class="text-center page-title mb-5">Product Details</h1>
    <div class="row justify-content-center" *ngIf="currentProduct.importType === 'manually'">
        <div class="col-lg-9">
            <nav class="nav nav-pills flex-column flex-sm-row">
                <button class="flex-sm-fill text-sm-center nav-link active" [ngClass]="{'active': show.details}" (click)="switchView('details')"><span>1</span>PRODUCT DETAILS</button>
                <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.medias}" (click)="switchView('medias')" [disabled]="!currentProduct.id"><span>2</span>MEDIAS</button>
                <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.characteristics}" (click)="switchView('characteristics')" [disabled]="!currentProduct.id"><span>3</span>CHARACTERISTICS</button>
                <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.prices}" (click)="switchView('prices')" [disabled]="!currentProduct.id"><span>4</span>CONDITION & PRICE</button>
                <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.seller}" (click)="switchView('seller')" [disabled]="!currentProduct.id"><span>5</span>SELLER INFO</button>
            </nav>
        </div>
    </div>
    <div class="row justify-content-center mt-5">
        <div class="col-lg-9">
            <div class="row" *ngIf="currentProduct.id  && currentProduct.importType === 'manually'">
                <div class="col-sm-8">
                    <div class="form-group ">
                        <label class="col-form-label">STATUS :
                            <span class="badge badge-pill badge-danger p-2 mx-2" *ngIf="currentProduct.isRemoved">Removed</span>
                            <span *ngIf="!currentProduct.isRemoved">
                              <span class="badge badge-pill badge-secondary p-2 mx-2" *ngIf="currentProduct.hasAgreeToTerms && !currentProduct.validated">Completed</span>
                              <span class="badge badge-pill badge-dark p-2 mx-2" *ngIf="!currentProduct.hasAgreeToTerms && !currentProduct.validated">Uncompleted</span>

                              <span class="badge badge-pill badge-primary p-2 mx-2" *ngIf="currentProduct.validated">Validated</span>
                              <span class="badge badge-pill badge-success p-2 mx-2" *ngIf="currentProduct.published && currentProduct.validated">Published</span>
                              <span class="badge badge-pill badge-warning p-2 mx-2" *ngIf="!currentProduct.available && currentProduct.published">Unavailable</span>
                            </span>
                            <span class="badge badge-pill badge-info p-2 mx-2" *ngIf="currentProduct.certificated">Certificated</span>
                        </label>
                    </div>
                </div>
                <div class="col-sm-4 text-right"  *ngIf="!currentProduct.isRemoved && currentProduct.hasAgreeToTerms">
                    <button type="button" class="btn btn-success ml-2 px-4" *ngIf="currentProduct.hasAgreeToTerms && !currentProduct.validated" (click)="validateProduct()" [disabled]="isLoading || !defaultVariant.inventory_quantity || !currentProduct.certificated">Valid it</button>
                    <button type="button" class="btn btn-info ml-2 px-4" *ngIf="!currentProduct.certificated" (click)="certifyProduct()" [disabled]="isLoading || !defaultVariant.inventory_quantity">Certify it</button>
                    <button type="button" class="btn btn-warning ml-2 px-4" *ngIf="currentProduct.published" (click)="unpublishedProduct()" [disabled]="isLoading">Unpublished it</button>
                </div>

                <div class="col-12 mb-3">
                    <hr/>
                    <div *ngIf="!defaultVariant.inventory_quantity && (!currentProduct.certificated || !currentProduct.validated)">
                        <div class="alert alert-warning my-3 text-center" role="alert">
                            To be able to validate or certify the product you need to update the Inventory Quantity first.
                        </div>
                    </div>
                    <div class="text-center"><ng-template #inventoryMessage></ng-template></div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="text-uppercase" for="shopifyId">Shopify Id</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [value]="currentProduct.shopifyId" name="shopifyId" id="shopifyId" readonly>
                            <div class="input-group-append" *ngIf="currentProduct.shopifyId">
                                <span class="input-group-text input-group-text-primary"><a [href]="shopifyAdminUrl + '/products/' + currentProduct.shopifyId" target="_blank"><i class="fas fa-external-link-alt"></i></a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="text-uppercase" for="inventory_quantity">Inventory Quantity</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="0" id="inventory_quantity"
                                   name="dp" [(ngModel)]="defaultVariant.inventory_quantity">
                            <div class="input-group-append">
                                <button class="btn btn-primary" (click)="updateInventoryQuantity()" type="button">
                                    Actualise
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div *ngIf="currentProduct.importType !== 'manually'">
                <div class="alert alert-success my-3 text-center" role="alert">
                    This product has been imported by <b>{{currentProduct.importType}}</b> from <b>{{currentProduct.source}}</b>
                </div>
                <div class="alert alert-primary mb-3" role="alert">
                    <pre [innerHTML]="currentProduct | jsonPretty"></pre>
                </div>
            </div>
        </div>
    </div>

    <app-list-product-details *ngIf="show.details && currentProduct.importType === 'manually'" (stepReturn)="stepReturn($event)" [business]="currentBusiness" [years]="years" [product]="currentProduct" [variant]="defaultVariant"  [colors-list]="colorsList" [materials-list]="materialsList" [data-structure]="realProductDatas"></app-list-product-details>
    <app-list-product-medias  *ngIf="show.medias && currentProduct.importType === 'manually'" (stepReturn)="stepReturn($event)" [product]="currentProduct" [photos-list]="photosList" [file-upload-folder]="fileUploadFolder"></app-list-product-medias>
    <app-list-product-characteristics *ngIf="show.characteristics && currentProduct.importType === 'manually'" (stepReturn)="stepReturn($event)" [years]="years" [product]="currentProduct" [variant]="defaultVariant" [file-upload-folder]="fileUploadFolder"></app-list-product-characteristics>
    <app-list-product-prices *ngIf="show.prices && currentProduct.importType === 'manually'" [variant]="defaultVariant" [product]="currentProduct" (stepReturn)="stepReturn($event)" [currencies]="currencies"></app-list-product-prices>
    <app-list-product-seller *ngIf="show.seller && currentProduct.importType === 'manually'" [product]="currentProduct" [variant]="defaultVariant" (stepReturn)="stepReturn($event)" [countries]="countries"></app-list-product-seller>

</div>
