import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService, NotLoginGuardService} from './_services';
import {
  LoginComponent,
  AccountComponent,
  DashboardComponent,
  ListProductsComponent,
  ProductComponent,
  BusinessOrderDetailsComponent,
  ListBusinessOrdersComponent,
  ListLuxifyOrdersComponent,
  LuxifyOrderDetailsComponent,
  ListBusinessesComponent,
  BusinessComponent,
  ListUsersComponent,
  UserDetailsComponent,
  AdminUserDetailsComponent,
  ListAdminUsersComponent,
  ListLifestyleComponent, LifestyleDetailsComponent,
} from '../pages';

const MAINMENU_ROUTES: Routes = [
  // full : makes sure the path is absolute path
  {path: '', component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: 'login', component: LoginComponent, canActivate: [NotLoginGuardService]},
  {path: 'account', component: AccountComponent, canActivate: [AuthGuardService]},
  {path: 'luxify-orders', component: ListLuxifyOrdersComponent, canActivate: [AuthGuardService]},
  {path: 'luxify-order/:id', component: LuxifyOrderDetailsComponent, canActivate: [AuthGuardService]},
  {path: 'business-orders', component: ListBusinessOrdersComponent, canActivate: [AuthGuardService]},
  {path: 'business-order/:id', component: BusinessOrderDetailsComponent, canActivate: [AuthGuardService]},
  {path: 'users', component: ListUsersComponent, canActivate: [AuthGuardService]},
  {path: 'admin-user', component: AdminUserDetailsComponent, canActivate: [AuthGuardService]},
  {path: 'admin-user/:id', component: AdminUserDetailsComponent, canActivate: [AuthGuardService]},
  {path: 'admin-users', component: ListAdminUsersComponent, canActivate: [AuthGuardService]},
  {path: 'user/:id', component: UserDetailsComponent, canActivate: [AuthGuardService]},
  {path: 'products', component: ListProductsComponent, canActivate: [AuthGuardService]},
  {path: 'product/:businessId', component: ProductComponent, canActivate: [AuthGuardService]},
  {path: 'product/:businessId/:id', component: ProductComponent, canActivate: [AuthGuardService]},
  {path: 'businesses', component: ListBusinessesComponent, canActivate: [AuthGuardService]},
  {path: 'business/:id', component: BusinessComponent, canActivate: [AuthGuardService]},
  {path: 'business/:id/:status', component: BusinessComponent, canActivate: [AuthGuardService]},
  {path: 'lifestyles', component: ListLifestyleComponent, canActivate: [AuthGuardService]},
  {path: 'lifestyle/:id', component: LifestyleDetailsComponent, canActivate: [AuthGuardService]},

  {path: '**', redirectTo: '/'}
];
export const CONST_ROUTING = RouterModule.forRoot(MAINMENU_ROUTES);
