<footer class="py-5 text-center text-sm-left" [hidden]="hideFooter">
  <div class="container">
    <div class="row">
      <div class="col-6 col-sm-3 mb-3">
        <h4>ABOUT LUXIFY</h4>
        <ul class="list-unstyled mb-0">
          <li>How it works</li>
          <li>Careers</li>
          <li>Press</li>
          <li>Blog</li>
          <li>Terms of Service</li>
          <li>Privacy Policy</li>
          <li>Contact Us</li>
          <li>Site Map</li>
        </ul>
      </div>
      <div class="col-6 col-sm-3 mb-3">
        <h4>BUYING & SELLING</h4>
        <ul class="list-unstyled mb-0">
          <li>Selling on Luxify</li>
          <li>Buying on Luxify</li>
          <li>Brand Directory</li>
        </ul>
      </div>
      <div class="col-6 col-sm-3 mb-3">
        <h4>CUSTOMER SERVICE</h4>
        <ul class="list-unstyled mb-0">
          <li>FAQ</li>
          <li>Customer Service</li>
          <li>Shipping</li>
        </ul>
      </div>
      <div class="col-6 col-sm-3 mb-3">
        <h4>FOLLOW US</h4>
        <a><img src="../../../assets/images/icons/instagram_icon.png" class="img-fluid mt-3" alt="Distributors and Resellers"></a>
        <a><img src="../../../assets/images/icons/facebook_color_icon.png" class="img-fluid mt-3 mx-3" alt="Distributors and Resellers"></a>
        <a><img src="../../../assets/images/icons/linkedin_icon.png" class="img-fluid mt-3" alt="Distributors and Resellers"></a>
      </div>
    </div>
    <div class="row align-items-end">
      <div class="col-sm-6 my-5 mb-sm-3 mt-sm-0">
        <a><img src="../../../assets/images/logo/luxify_logo_white.png" class="img-fluid mt-3" alt="Distributors and Resellers"></a>
      </div>
      <div class="col-sm-6 mb-3">
        <form class="" (ngSubmit)="subscribe()" #subscribeForm="ngForm">
          <h4 class="mb-3">SIGN UP FOR UPDATES</h4>
          <ng-template #alertMessage></ng-template>
          <div class="input-group mb-0 mt-2">
            <label for="subscribeEmail" hidden></label>
            <input type="text" class="form-control form-control-lg" [(ngModel)]="userEmail" [email]="true" id="subscribeEmail" name="email"  placeholder="Enter your email address" required>
            <div class="input-group-append">
              <button class="btn btn-primary" type="submit" [disabled]="!subscribeForm.form.valid || isLoading">SEND</button>
            </div>
          </div>
          <p class="white-color small-info mt-3 mb-0">By registering I accept the terms of the privacy policy and agree to receive informational emails regarding Luxify news and updates</p>
        </form>
      </div>
    </div>
  </div>
</footer>
