<app-menu *ngIf="user && user.id"></app-menu>
<div class="router-outlet-content">
  <div [ngClass]="{'admin-view container-fluid bg-gray-light': user && user.id}">
    <div [ngClass]="{'row': user && user.id}">
      <app-left-menu *ngIf="user && user.id" class="col-3 col-lg-2 position-fixed d-none d-md-block"></app-left-menu>
      <div [ngClass]="{'col-md-9 offset-md-3 col-lg-10 offset-lg-2 pt-5': user && user.id}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<app-loader></app-loader>
