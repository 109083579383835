<section class="bg-gray-light">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="updateProductDetails()" #updateProductDetailsForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="form-group">
            <label class="col-form-label">HOW WILL YOU LIST YOUR ITEM(S)<span class="required">*</span></label>
            <div class="form-group">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [(ngModel)]="product.importType" name="importType" id="importTypeAPI" value="api" disabled>
                <label class="form-check-label" for="importTypeAPI">API</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [(ngModel)]="product.importType" name="importType" id="importTypeManually" value="manually" disabled>
                <label class="form-check-label" for="importTypeManually">MANUALLY</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="importType" id="importTypeEcommerce" value="e-commerce" disabled>
                <label class="form-check-label" for="importTypeEcommerce">E-COMMERCE PLATFORM<a data-toggle="modal" data-target="#helpModal"><i class="fas fa-question primary-color pl-2"></i></a></label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="importType" id="importTypeIMS" value="ims" disabled>
                <label class="form-check-label" for="importTypeIMS">INVENTORY MANAGEMENT SYSTEM<a data-toggle="modal" data-target="#helpModal"><i class="fas fa-question primary-color pl-2"></i></a></label>
              </div>
            </div>
          </div>

          <div *ngIf="product.importType === 'manually'">
            <hr/>
            <div class="form-group">
              <label class="col-form-label">FOR WHO?<span class="required">*</span></label>
              <div class="form-group">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.customerType" name="customerType" id="customerTypeWomen" value="women" required>
                  <label class="form-check-label" for="customerTypeWomen">WOMEN</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.customerType" name="customerType" id="customerTypeMen" value="men">
                  <label class="form-check-label" for="customerTypeMen">MEN</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.customerType" name="customerType" id="customerTypeUnisex" value="unisex">
                  <label class="form-check-label" for="customerTypeUnisex">BOTH</label>
                </div>
              </div>
            </div>
            <hr/>
            <div class="form-group">
              <label class="col-form-label">CHOOSE A CATEGORY<span class="required">*</span></label>
              <div class="form-group">
                <div class="form-check form-check-inline" *ngFor="let category of categoriesList">
                  <input class="form-check-input" type="radio" (change)="onChangeCategory()" [(ngModel)]="product.category" [name]="category" [id]="'category-' + category" [value]="category" required>
                  <label class="form-check-label text-uppercase" [for]="'category-'+category">{{category}}</label>
                </div>
              </div>
            </div>
            <hr/>
            <div class="form-group">
              <label class="col-form-label" for="subCategory">SUB CATEGORY</label>
              <input type="text" id="subCategory" [(ngModel)]="product.subCategory" name="subCategory" hidden required>
              <div class="alert  mb-0 alert-input" [ngClass]="{'alert-dark': !product.category,'alert-light' : product.category}" (click)="openModal('sub-category')">
                {{product.subCategory}}<button type="button" class="close"><i class="fas fa-caret-down"></i></button>
              </div>
              <p class="small primary-color font-italic" *ngIf="!product.category">Select the category first.</p>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="brand">BRAND</label>
              <input type="text" id="brand" [(ngModel)]="product.brand" name="brand" hidden required>
              <div class="alert mb-0 alert-input"  [ngClass]="{'alert-dark': !product.subCategory,'alert-light' : product.subCategory}" (click)="openModal('brand')">
                {{product.brand}}<button type="button" class="close"><i class="fas fa-caret-down"></i></button>
              </div>
              <p class="small primary-color font-italic" *ngIf="!product.subCategory">Select the sub category first.</p>

            </div>
            <div class="form-group">
              <label class="col-form-label" for="model">MODEL</label>
              <input type="text" id="model" [(ngModel)]="product.model" name="model" hidden required>
              <div class="alert mb-0 alert-input" [ngClass]="{'alert-dark': !product.brand,'alert-light' : product.brand}" (click)="openModal('model')">
                {{product.model}}<button type="button" class="close"><i class="fas fa-caret-down"></i></button>
              </div>
              <p class="small primary-color font-italic" *ngIf="!product.brand">Select the brand first.</p>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="year">YEAR</label>
              <select class="form-control" [(ngModel)]="product.year" (ngModelChange)="onChange()"
                      name="year" id="year" required>
                <option *ngFor="let item of years" [ngValue]="item">{{item}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="material">MATERIAL</label>
              <input type="text" id="material" [(ngModel)]="variant.material" name="material" hidden required>
              <div class="alert alert-light alert-input" (click)="openModal('material')">
                {{variant.material}}<button type="button" class="close"><i class="fas fa-caret-down"></i></button>
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="color">COLOR</label>
              <input type="text" id="color" [(ngModel)]="variant.color" name="color" hidden required>
              <div class="alert alert-light alert-input" (click)="openModal('color')">
                {{variant.color}}<button type="button" class="close"><i class="fas fa-caret-down"></i></button>
              </div>
            </div>
            <hr/>
            <div class="text-right my-5">
              <button type="submit" class="btn btn-primary" [disabled]="!updateProductDetailsForm.form.valid">CONFIRM</button>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</section>

<div class="modal fade" id="selectFromListModal" tabindex="-1" role="dialog" aria-labelledby="selectFromListModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <div class="input-group input-group-modal">
          <input type="text" class="form-control" placeholder="Search" (input)="onSearchChange($event)">
          <div class="input-group-append">
            <span class="input-group-text" id="search1"><i class="fas fa-search"></i></span>
          </div>
        </div>
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row my-5">
          <div [ngClass]="{'col-sm-4': modalListFilter.length >20,'col-sm-6': modalListFilter.length >10 && modalListFilter.length <=20,'col-12': modalListFilter.length <=10}">
            <div class="form-check" *ngFor="let item of modalListFilter | slice:0:10; let i=index">
              <input class="form-check-input" type="radio" (change)="selectOnModal(item)" name="brand" [id]="'item-'+item" [value]="item">
              <label class="form-check-label"  [for]="'item-'+item">{{item}}</label>
            </div>
            <div class="form-check" *ngIf="modalListFilter.length<10">
              <input class="form-check-input" type="radio" (change)="selectOnModal({label:'Suggest A New Or Missing Top Luxury '+ modalLabel,key:'other'})" name="brand" id="item-other-1" value="other">
              <label class="form-check-label text-capitalize" for="item-other-1">Suggest A New Or Missing Top Luxury {{modalLabel}}</label>
            </div>
          </div>
          <div class="" *ngIf="modalListFilter.length>=10" [ngClass]="{'col-sm-4': modalListFilter.length >20,'col-sm-6': modalListFilter.length >10 && modalListFilter.length <=20}">
            <div class="form-check" *ngFor="let item of modalListFilter | slice:10:20; let i=index">
              <input class="form-check-input" type="radio" (change)="selectOnModal(item)" name="brand" [id]="'item-'+item" [value]="item">
              <label class="form-check-label"  [for]="'item-'+item">{{item}}</label>
            </div>
            <div class="form-check" *ngIf="modalListFilter.length>=10 && modalListFilter.length<20">
              <input class="form-check-input" type="radio" (change)="selectOnModal({label:'Suggest A New Or Missing Top Luxury '+ modalLabel,key:'other'})" name="brand" id="item-other-2" value="other">
              <label class="form-check-label text-capitalize" for="item-other-2">Suggest A New Or Missing Top Luxury {{modalLabel}}</label>
            </div>
          </div>
          <div class="" *ngIf="modalListFilter.length>=20" [ngClass]="{'col-sm-4': modalListFilter.length >20}">
            <div class="form-check" *ngFor="let item of modalListFilter | slice:20:28; let i=index">
              <input class="form-check-input" type="radio" (change)="selectOnModal(item)" name="brand" [id]="'item-'+item" [value]="item">
              <label class="form-check-label"  [for]="'item-'+item">{{item}}</label>
            </div>
            <div class="form-check" *ngIf="modalListFilter.length>=20">
              <input class="form-check-input" type="radio" (change)="selectOnModal({label:'Suggest A New Or Missing Top Luxury '+ modalLabel,key:'other'})" name="brand" id="item-other-3" value="other">
              <label class="form-check-label text-capitalize" for="item-other-3">Suggest A New Or Missing Top Luxury {{modalLabel}}</label>
            </div>
          </div>
        </div>
        <hr/>
        <p class="small">We only sell a selected list of <span class="text-lowercase">{{modalLabelPlural}}</span>.</p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="selectFromListPreviewModal" tabindex="-1" role="dialog" aria-labelledby="selectFromListPreviewModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="row justify-content-center">
      <div class="col-sm-8 row-eq-height">
        <div class="modal-content">
          <div class="modal-header pb-0">
            <div class="input-group input-group-modal">
              <input type="text" class="form-control" placeholder="Search" (input)="onSearchChange($event)">
              <div class="input-group-append">
                <span class="input-group-text" id="search2"><i class="fas fa-search"></i></span>
              </div>
            </div>
            <button type="button" class="close close-custom d-md-none" data-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="row my-5">
              <div [ngClass]="{'col-sm-6': modalListFilter.length >10,'col-12': modalListFilter.length <=10}">
                <div class="form-check" *ngFor="let item of modalListFilter | slice:0:10; let i=index">
                  <input class="form-check-input" type="radio" (change)="selectOnModal(item)" name="brand" [id]="'item-2-'+item.model" [value]="item.model">
                  <label class="form-check-label" [for]="'item-2-'+item.model">{{item.model}}</label>
                </div>
                <div class="form-check" *ngIf="modalListFilter.length<10">
                  <input class="form-check-input" type="radio" (change)="selectOnModal({label:'Suggest A New Or Missing Top Luxury '+ modalLabel,key:'other'})" name="brand" id="item-other-4" value="other">
                  <label class="form-check-label text-capitalize" for="item-other-4">Suggest A New Or Missing Top Luxury {{modalLabel}}</label>
                </div>
              </div>
              <div class="col-sm-6" *ngIf="modalListFilter.length>=10">
                <div class="form-check" *ngFor="let item of modalListFilter | slice:10:18; let i=index">
                  <input class="form-check-input" type="radio" (change)="selectOnModal(item)" name="brand" [id]="'item-2-'+item.model" [value]="item.model">
                  <label class="form-check-label"  [for]="'item-2-'+item.model">{{item.model}}</label>
                </div>
                <div class="form-check" *ngIf="modalListFilter.length>=10">
                  <input class="form-check-input" type="radio" (change)="selectOnModal({label:'Suggest A New Or Missing Top Luxury '+ modalLabel,key:'other'})" name="brand" id="item-other-5" value="other">
                  <label class="form-check-label text-capitalize" for="item-other-5">Suggest A New Or Missing Top Luxury {{modalLabel}}</label>
                </div>
              </div>
            </div>
            <hr/>
            <p class="small">We only sell a selected list of <span class="text-lowercase">{{modalLabelPlural}}</span>.</p>
          </div>
        </div>
      </div>

      <div class="col-sm-4 row-eq-height d-none d-md-flex">
        <div class="modal-content">
          <div class="modal-header pb-0">
            <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div class="modal-body pt-0 text-center">
            <h3 class="text-capitalize my-5 text-truncate">{{modalSelectedItem.model}}</h3>
            <img alt="selected image" class="img-fluid" *ngIf="modalSelectedItem && modalSelectedItem.image" [src]="modalSelectedItem.image">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-container" role="document">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0">
        <h5 class="mb-0">E-COMMERCE PLATFORM (Coming soon)</h5>
        <p class="small font-weight-light">E.g. Shopify, Magento, Prestashop, etc.</p>
        <h5 class="mb-0 mt-4">INVENTORY MANAGEMENT SYSTEM (Coming soon)</h5>
        <p class="small font-weight-light">E.g.Cin7, Vend, etc.</p>
      </div>
    </div>
  </div>
</div>
