<div class="page-content container-fluid">
    <h1 class="page-title text-center mb-5">Admin Users</h1>
    <div class="text-right mb-3"><a [routerLink]="'/admin-user'" class="btn btn-primary">Add User</a></div>
    <p class="text-center small primary-color">Please note that the data can be sort or filter but not both at the same time</p>
    <ag-grid-angular
            class="ag-theme-balham w-100 mb-5"
            (gridReady)="onGridReady($event)"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [gridOptions]="gridOptions"
            [modules]="modules"
            [floatingFilter]="true">
    </ag-grid-angular>
</div>
