import {
    Component, ViewEncapsulation, OnInit
} from '@angular/core';
import * as moment from 'moment';
import {Business, BusinessOrder, Product} from '../../app/_models';
import {BusinessService, PaymentService, ProductService} from '../../app/_services';
import {LoaderService} from '../../app/shared/loader/loader.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class DashboardComponent implements OnInit {
    options;
    products = {
        data: [],
        thisMonthlyCount: 0
    };
    individuals = {
        data: [],
        thisMonthlyCount: 0
    };
    businesses = {
        data: [],
        thisMonthlyCount: 0
    };
    payments = {
        data: [],
        thisMonthlyCount: 0
    };

    nbLoaded = 3;
    loadedCpt = 0;
    constructor(private  productService: ProductService,
                private  businessService: BusinessService,
                private  paymentService: PaymentService,
                private loaderService: LoaderService) {

    }

    ngOnInit() {
        this.loaderService.show();
        this.options = {
            chart: {
                type: 'discreteBarChart',
                height: 450,
                margin: {
                    top: 20,
                    right: 20,
                    bottom: 50,
                    left: 55
                },
                x: (d) => {
                    return d.label;
                },
                y: (d) => {
                    return d.value;
                },
                showValues: true,
                valueFormat: (d) => {
                    return d3.format(',f')(d);
                },
                tooltip: {
                    enabled: false
                },
                duration: 500,
                yAxis: {
                    axisLabelDistance: -10
                }
            }
        };
        this.getProducts();
        this.getBusinesses();
        this.getPayments();
    }

    getProducts() {
        this.productService.list()
            .subscribe((products: Product[]) => {
                this.initMonthlyData(products)
                    .then((monthlyProduct) => {
                        this.products = {
                            data: [{
                                key: 'Experiences',
                                values: monthlyProduct.values
                            }],
                            thisMonthlyCount: monthlyProduct.thisMonthlyCount
                        };
                        this.loadedCpt++;
                        if (this.loadedCpt === this.nbLoaded) {
                            this.loaderService.hide();
                        }
                    });
            });
    }

    getPayments() {
        this.paymentService.getBusinessOrders()
            .subscribe((payments: BusinessOrder[]) => {
                this.initMonthlyData(payments)
                    .then((monthlyProduct) => {
                        this.payments = {
                            data: [{
                                key: 'Experiences',
                                values: monthlyProduct.values
                            }],
                            thisMonthlyCount: monthlyProduct.thisMonthlyCount
                        };
                        this.loadedCpt++;

                        if (this.loadedCpt === this.nbLoaded) {
                            this.loaderService.hide();
                        }
                    });
            });
    }

    getBusinesses() {
        this.businessService.list()
            .subscribe((businesses: Business[]) => {
                const individualArray = businesses.filter((business: Business) => {
                    return business.businessType === 'individual';
                });

                this.initMonthlyData(individualArray)
                    .then((monthlyIndividual) => {
                        this.individuals = {
                            data: [{
                                key: 'Experiences',
                                values: monthlyIndividual.values
                            }],
                            thisMonthlyCount: monthlyIndividual.thisMonthlyCount
                        };
                        const businessArray = businesses.filter((business: Business) => {
                            return business.businessType !== 'individual';
                        });
                        return this.initMonthlyData(businessArray);
                    })
                    .then((monthlyBusiness) => {
                        this.businesses = {
                            data: [{
                                key: 'Experiences',
                                values: monthlyBusiness.values
                            }],
                            thisMonthlyCount: monthlyBusiness.thisMonthlyCount
                        };
                        this.loadedCpt++;

                        if (this.loadedCpt === this.nbLoaded) {
                            this.loaderService.hide();
                        }
                    });
            });
    }

    initMonthlyData(list: any[]): Promise<{ values: any[], thisMonthlyCount: number }> {
        return new Promise((resolve) => {
            const size = list.length;
            const values = [];
            let currentMonth;
            let thisMonthlyCount = 0;
            let sum = 0;
            let cpt = 0;
            if (size === 0) {
                resolve({values, thisMonthlyCount});
                return {values, thisMonthlyCount};
            }
            let lastMonth = moment(list[0].created_at).format('MMM YY');
            const thisMonth = moment().format('MMM YY');
            list.forEach((product: Product, index: number) => {
                currentMonth = moment(product.created_at).format('MMM YY');
                if (currentMonth !== lastMonth || index === size - 1) {
                    cpt++;
                    values.push({
                        label: lastMonth,
                        value: (sum += cpt)
                    });
                    if (thisMonth === lastMonth) {
                        thisMonthlyCount = cpt;
                        cpt = 0;
                    }
                } else {
                    cpt++;
                }
                lastMonth = currentMonth;
            });
            resolve({values, thisMonthlyCount});
        });
    }
}
