<nav class="navbar navbar-expand-md fixed-top navbar-dark" [ngClass]="{'d-none' : isHidden , 'bg-dark':!isTransparent, 'bg-transparent' : isTransparent}">
  <a class="navbar-brand" [routerLink]="['/']"><img height="30px" alt="Luxify" [src]="isTransparent ? 'assets/images/logo/luxify-logo.svg' : 'assets/images/logo/luxify-logo.svg'"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto text-uppercase text-center">



      <li class="nav-item d-md-none" routerLinkActive="active"><a class="nav-link" [routerLink]="['/business-orders']"><i class="fas fa-shopping-cart"></i> Business Orders</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active"><a class="nav-link" [routerLink]="['/luxify-orders']"><i class="fas fa-store"></i> Luxify Orders</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active"><a class="nav-link" [routerLink]="['/products']"><i class="fas fa-layer-group"></i> Products</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active"><a class="nav-link" [routerLink]="['/businesses']"><i class="fas fa-store"></i> Markets</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active"><a class="nav-link" [routerLink]="['/users']"><i class="fas fa-users"></i> Users</a></li>
      <li class="nav-item d-md-none mb-5" routerLinkActive="active"><a class="nav-link" [routerLink]="['/admin-users']"><i class="fas fa-user-secret"></i> Admin users</a></li>

      <li class="nav-item d-md-none" routerLinkActive="active"><a class="nav-link" [routerLink]="['/account']">My Profile</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active"><button class="btn btn-link nav-link btn-block text-uppercase" (click)="logout()">Logout</button></li>

      <li class="nav-item dropdown d-none d-md-block">
        <a class="nav-link " href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="custom-avatar">{{user.email[0]}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right text-capitalize text-right" aria-labelledby="userDropdown">
          <a class="dropdown-item" [routerLink]="['/account']">My Profile</a>
          <span class="dropdown-item text-capitalize" (click)="logout()">Logout</span>
        </div>
      </li>

    </ul>
  </div>
</nav>
