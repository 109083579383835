import {
  Component, Input, Output, EventEmitter
} from '@angular/core';

import {ProductService} from '../../../../app/_services';
import {Product, Variant} from '../../../../app/_models';
import {LoaderService} from '../../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-product-characteristics',
  templateUrl: './characteristics.component.html',
  styleUrls: ['./characteristics.component.scss'],

})
export class ProductCharacteristicsComponent {
  @Input('product') product: Product = {};
  @Input('variant') variant: Variant = {};
  @Input('file-upload-folder') fileUploadFolder: string;
  @Input('years') years: number[] = [];
  @Output() stepReturn: EventEmitter<{ step: string, product: Product }> = new EventEmitter<{ step: string, product: Product }>();

  needToUpdate = false;
  isLoading = false;

  constructor(private loaderService: LoaderService,
              private productService: ProductService) {
  }

  getIdFilePath(filePath: { absUrl: string, path: string }) {
    this.isLoading = true;
    this.loaderService.show();
    this.variant.invoice = {src : filePath.absUrl};
    const data: Product = {
      id: this.product.id,
      variants: [this.variant]
    };
    this.productService.update(data).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }

  deleteIdFilePath() {
    this.isLoading = true;
    this.loaderService.show();
    this.variant.invoice = null;
    const data: Product = {
      id: this.product.id,
      variants: [this.variant]
    };
    this.productService
      .update(data)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  goBack() {
    return this.stepReturn.emit({step: 'medias', product : null});
  }

  onChange() {
    this.needToUpdate = true;
  }

  updateProduct() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'prices', product : null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.product.variants = [this.variant];
    this.productService.update(this.product)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
        return this.stepReturn.emit({step: 'prices', product : this.product});
      });
  }
}
