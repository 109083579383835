<section class="bg-gray-light" *ngIf="business && business.id">
  <div class="container py-5">
    <form class="" (ngSubmit)="updateBusiness()" #updateBusinessForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <p class="text-center mb-5">
            Tell us who to contact on behalf of your business. It is important that this information is accurate so that we may contact the right person in case of an enquiry or emergency.
          </p>
          <div class="form-group">
            <label class="col-form-label" for="firstName">First name<span class="required">*</span></label>
            <input type="text" class="form-control form-control-lg" id="firstName" placeholder="Enter first name here" [(ngModel)]="business.representativeFirstName" (change)="onChange()" name="firstName" required>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="lastName">Last name<span class="required">*</span></label>
            <input type="text" class="form-control form-control-lg" id="lastName" placeholder="Enter last name here" [(ngModel)]="business.representativeLastName" (change)="onChange()" name="lastName" required>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="representativePhone">Phone<span class="required">*</span></label>
            <input type="text" class="form-control form-control-lg" ng2TelInput (ng2TelOutput)="onPhoneChanges($event)" (hasError)="onPhoneError($event)" name="representativePhone" id="representativePhone" [value]="business.representativePhone || null" >
            <div class="alert alert-danger mt-1" role="alert" *ngIf="!isPhoneNumberValid">This phone number is not valid!</div>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="representativeEmail">Email<span class="required">*</span></label>
            <input type="text" [email]="true" class="form-control form-control-lg" id="representativeEmail" placeholder="Enter email here" [(ngModel)]="business.representativeEmail" (change)="onChange()" name="representativeEmail" required>
          </div>
          <div class="form-group" [ngClass]="{'is-invalid': !business.representativeDateOfBirth }">
            <label class="" for="representativeDateOfBirth">Date of birth<span class="required">*</span></label>
            <div class="input-group">
              <input class="form-control form-control-lg" placeholder="DD/MM/YYYY" id="representativeDateOfBirth"
                     name="dp" [(ngModel)]="business.representativeDateOfBirth" ngbDatepicker #d="ngbDatepicker"
                     [maxDate]="datePickerOption.maxDate" [minDate]="datePickerOption.minDate"  (change)="onChange()"
                     (click)="d.toggle()" readonly required>
              <div class="input-group-append">
                <button class="btn btn-primary" (click)="business.representativeDateOfBirth=null" type="button">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="text-center">
            <h2 class="mt-3 my-5 text-center">Your goverment-issued ID</h2>
            <p>
              Upload a photo or a scanned image of your government-issued ID such as your driver’s license or a passport. We verify your identification to build trust in our community.
            </p>
            <p class="primary-color font-italic">
              You may choose to skip this for now and upload your ID when its convinient for you, from your Account Settings.
            </p>
            <div class="form-group">
              <app-file-upload *ngIf="business.id && fileUploadFolder" [currentfileurl]="business.representativeIdentityProof" [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="400"
                           [extensions]="['png','jpg','jpeg']" (fileDeleted)="deleteIdFilePath()" (filepath)="getIdFilePath($event)" id="identityProof" [max-size]="10000000"></app-file-upload>
              <p class="text-left form-text text-muted small">The uploaded file needs to be a color image (smaller than 8,000px by 8,000px), in JPG or PNG format, and less than 10 MB in size.</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-6 text-left">
              <button type="button" (click)="goBack()" class="btn btn-outline-primary">GO BACK TO STEP 2 </button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!updateBusinessForm.form.valid || !business.representativePhone">UPDATE</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
