<section class="left-menu w-100 ">
  <div>
    <h6 class="text-truncate">ECOMMERCE</h6>
    <ul class="list-unstyled">
      <li class="text-truncate"><a [routerLink]="['/luxify-orders']"><i class="fas fa-shopping-cart"></i> Luxify Orders</a></li>
      <li class="text-truncate"><a [routerLink]="['/business-orders']"><i class="fas fa-store"></i> Business Orders</a></li>
    </ul>
  </div>
  <div>
    <h6 class="text-truncate">INVENTORY</h6>
    <ul class="list-unstyled">
      <li class="text-truncate"><a [routerLink]="['/products']"><i class="fas fa-layer-group"></i> Products</a></li>
      <li class="text-truncate"><a [routerLink]="['/businesses']"><i class="fas fa-store"></i> Businesses</a></li>
      <li class="text-truncate"><a [routerLink]="['/users']"><i class="fas fa-users"></i> Users</a></li>
    </ul>
  </div>
  <div>
    <h6 class="text-truncate">STRUCTURES</h6>
    <ul class="list-unstyled">
      <!--<li class="text-truncate"><a [routerLink]="['/']" ><i class="fas fa-layer-group"></i> Categories</a></li>-->
      <li class="text-truncate"><a [routerLink]="['/lifestyles']" ><i class="fas fa-layer-group"></i> Lifestyle</a></li>
    </ul>
  </div>
  <div>
    <h6 class="text-truncate">ADMINISTRATION</h6>
    <ul class="list-unstyled">
      <li class="text-truncate"><a [routerLink]="['/admin-users']"><i class="fas fa-user-secret"></i> Admin users</a></li>
    </ul>
  </div>
</section>
