import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BusinessOrder, User} from '../_models/';
import {AngularFirestore} from '@angular/fire/firestore';
import * as moment from 'moment';


@Injectable()
export class AdminService {
    private dataBehavior: BehaviorSubject<any[]>;
    private lastCollectionPath: string;
    private maxToLoadOnCollection = 7000;
    public data: Observable<any[]>;
    latestEntry: any;
    latestParams: {short: any, filter: any};
    constructor(private db: AngularFirestore) {
    }

    list(): Observable<User[]> { // note: to use Observable<User[]> here we have to set all User properties as optional with ?
        return this.db.collection('admin-users')
            .snapshotChanges().pipe( // we can not call valueChanges() here as it would not return the id so this is a workaround
                map((actions: any) => {
                    return actions.map((a: any) => {
                        const data = a.payload.doc.data() as User;
                        data.id = a.payload.doc.id;
                        return data;
                    });
                }),
                catchError(this.handleError('list user', []))
            );
    }

    async setAdmin(userId: string, userEmail: string, role: string) {
        let userDoc = this.db.doc('admin-users/' + userId);
        const newUser: any = {id: userId, email: userEmail, role, updated_at: moment().format()};
        if (!userId && userEmail) {
            const user = await this.getAdminUsersByEmail(userEmail);
            if (!user) {
                throw ({success: false, message: `This user doesn't exist`});
            }
            userDoc = this.db.doc('admin-users/' + user.id);
            newUser.id = user.id;
            newUser.email = user.email;
            newUser.created_at = moment().format();
        }
        return userDoc.set(newUser, {merge: true});
    }

    async getAdminUsersByEmail(userEmail: string) {
        const users = await new Promise((resolve) => {
            return this.db.collection('users', ref => {
                return ref.where('email', '==', userEmail).limit(1);
            }).snapshotChanges().pipe(map((actions: any) => {
                return actions.map((a: any) => {
                    const data = a.payload.doc.data() as BusinessOrder;
                    const id = a.payload.doc.id;
                    return {id, ...data};
                });
            })).subscribe((data: any[]) => {
                resolve(data);
            });
        });
        return users[0];
    }

    get(id: string): Observable<User> {
        return this.db.doc('admin-users/' + id)
            .snapshotChanges().pipe(
                map((a: any) => {
                    const data = a.payload.data() as User;
                    if (data) {
                        data.id = a.payload.id;
                    }
                    return data;
                }),
                catchError(this.handleError<User>(`getUser id=${id}`))
            );
    }

    getLifestyle(id: string): Observable<User> {
        return this.db.doc('lifestyle/' + id)
            .snapshotChanges().pipe(
                map((a: any) => {
                    const data = a.payload.data() as User;
                    if (data) {
                        data.id = a.payload.id;
                    }
                    return data;
                }),
                catchError(this.handleError<User>(`get lifestyle id=${id}`))
            );
    }

    // You need to return the doc to get the current cursor.
    getCollection(ref, queryFn?): Observable<any[]> {
        return this.db.collection(ref, queryFn).snapshotChanges()
            .pipe(map((actions: any[]) => {
                if (actions && actions.length > 0) {
                    this.latestEntry = actions[actions.length - 1].payload.doc;
                }
                return actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...data};
                });
            }), catchError(this.handleError('list ' + ref, [])));
    }

    // In your first query you subscribe to the collection and save the latest entry
    getFirstData(collectionPath, params, maxSize): Promise<any[]> {
        this.maxToLoadOnCollection = maxSize;
        return new Promise((resolve) => {
            this.dataBehavior = new BehaviorSubject([]);
            this.data = this.dataBehavior.asObservable();
            this.lastCollectionPath = collectionPath;
            this.latestParams = params;
            // setup dynamic querry
            const query = this.setupQuerry(this.latestParams);
            return this.getCollection(this.lastCollectionPath, ref => query)
                .subscribe(data => {
                    this.dataBehavior.next(data);
                    resolve(data);
                });
        });
    }

    getNextData(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (this.lastCollectionPath) {
                this.dataBehavior = new BehaviorSubject([]);
                this.data = this.dataBehavior.asObservable();
                const query = this.setupQuerry(this.latestParams);
                return this.getCollection(this.lastCollectionPath, ref => query.startAfter(this.latestEntry))
                    .subscribe(data => {
                        if (data.length) {
                            this.dataBehavior.next(data);
                            resolve(data);
                        } else {
                            resolve([]);
                        }
                    });
            } else {
                reject(`No ${this.lastCollectionPath}`);
            }
        });
    }

    private setupQuerry(params) {
        // setup dynamic querry
        let query = this.db.collection(this.lastCollectionPath).ref
            .limit(this.maxToLoadOnCollection);

        if (params.filter && Object.keys(params.filter).length > 0) {
            for (const property in params.filter) {
                if (params.filter.hasOwnProperty(property)) {
                    query = query.where(property, '==', params.filter[property].filter);
                }
            }
        } else if (params.orderBy && params.orderBy.colId) {
            query = query.orderBy(params.orderBy.colId, params.orderBy.sort);
        }
        return query;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
