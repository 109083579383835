import {
    Component, OnInit
} from '@angular/core';

import {AllCommunityModules} from '@ag-grid-community/all-modules';

import {AdminService} from '../../../app/_services';
import {RouterLinkRendererComponent} from '../../../app/_directives';
import {TimeToLabelPipe} from '../../../app/_pipe';
import {IDatasource, IGetRowsParams, GridApi} from '@ag-grid-community/all-modules';

@Component({
    selector: 'app-list-lifestyles',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    entryComponents: [RouterLinkRendererComponent],
    providers: [TimeToLabelPipe]
})

export class ListLifestyleComponent implements OnInit {
    defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true
    };
    columnDefs = [
        {
            headerName: 'Id', field: 'id',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/lifestyle',
            }
        },
        {field: 'category'},
        {field: 'luxify_id'},
        {field: 'name'},
        {field: 'type'}
    ];
    modules = AllCommunityModules;
    isFirstLoad = true;
    totalRecords = 0;
    gridApi: GridApi;
    maxSize = 100;
    gridOptions = {
        pagination: true,
        rowModelType: 'infinite',
        cacheBlockSize: this.maxSize, // you can have your custom page size
        paginationPageSize: this.maxSize // pagesize
    };

    currentParams = {orderBy: {}, filter: {}};
    dataSource: IDatasource = {
        getRows: (params: IGetRowsParams) => {
            const tempParams: any = {orderBy: params.sortModel[0] || {}, filter: params.filterModel};
            if ((JSON.stringify(tempParams) !== JSON.stringify(this.currentParams))) {
                this.isFirstLoad = true;
                this.currentParams = tempParams;
                this.totalRecords = 0;
            }
            let dataPromise: Promise<any[]>;
            if (this.isFirstLoad || this.totalRecords === 0) {
                dataPromise = this.adminService.getFirstData('lifestyle', this.currentParams, this.maxSize);
                this.isFirstLoad = false;
            } else {
                dataPromise = this.adminService.getNextData();
            }
            dataPromise.then(data => {
                const size = data.length;
                this.totalRecords += size;
                let pagCount = this.totalRecords;
                if (size === this.maxSize) {
                    pagCount += this.maxSize * 2;
                }
                params.successCallback(
                    data,
                    pagCount
                );
            });
        }
    };

    constructor(private adminService: AdminService) {
    }

    ngOnInit() {
    }

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.setDatasource(this.dataSource);
    }
}
