<section class="bg-gray-light">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="updateProduct()" #updateProductPricesForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="form-group">
            <label class="col-form-label">CONDITION</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.condition" name="condition" id="conditionVeryGood" value="very-good" required>
              <label class="form-check-label" for="conditionVeryGood">
                VERY GOOD CONDITION
              </label>
              <p class="mt-5">
                A bag in very good condition is a second-hand bag which has been barely used and shows no defects.<br/><br/>
                <span class="primary-color font-weight-bold">Reasons for refusal</span> - Damaged fabric or material (outside and inside) - Worn or damaged corners - Stains or marks (outside and inside) - Broken zip - Scratches / oxidation / rust - Modifications
              </p>
            </div>
            <hr/>
            <div class="form-check">
              <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.condition" name="condition" id="conditionGood" value="good">
              <label class="form-check-label" for="conditionGood">
                GOOD CONDITION
              </label>
              <p class="mt-5">
                A bag in good condition is a second-hand bag which has been used and well maintained. If the piece has any defects they must be mentioned in the description and visible in the photos.
                <br/><br/>
                <span class="primary-color font-weight-bold">Reasons for refusal</span> - Damaged fabric or material (outside) - Punctured corners - Obvious stains (outside and inside) - Broken zip - Persistent smells – Modifications
              </p>
            </div>
            <hr/>
            <div class="form-check">
              <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.condition" name="condition" id="conditionFair" value="fair">
              <label class="form-check-label" for="conditionFair">
                FAIR CONDITION
              </label>
              <p class="mt-5">
                A bag in correct condition is a bag which has been used frequently and may show defects. If the piece has any defects they must be mentioned in the description and visible in the photos.
                <br/><br/>
                <span class="primary-color font-weight-bold">Reasons for refusal</span> - No criteria excluding items as correct condition
              </p>
            </div>
            <hr/>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label class="col-form-label" for="currency">SELLING PRICE</label>
                <p>Currency</p>
                <select class="form-control form-control-lg" id="currency" [(ngModel)]="product.currency" (ngModelChange)="onChange()" name="currency" required>
                  <option *ngFor="let item of currencies" [ngValue]="item.code" >{{item.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 ">
              <div class="form-group">
                <label class="col-form-label" for="price">ON LUXIFY</label>
                <div class="input-group">
                  <input type="number" class="form-control" (change)="onChange()" [(ngModel)]="variant.price" name="price" id="price" required>
                  <div class="input-group-append">
                    <span class="input-group-text input-group-text-primary">{{product.currency || '$'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-form-label" for="priceForMe">FOR ME</label>
                <div class="input-group">
                  <input type="number" class="form-control" [value]="getPriceFroSeller()" name="priceForMe" id="priceForMe" readonly>
                  <div class="input-group-append">
                    <span class="input-group-text input-group-text-primary">{{product.currency || '$'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row mt-5 mb-5">
            <div class="col-6 text-left">
              <button type="button" class="btn btn-outline-primary" (click)="goBack()">GO BACK</button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!updateProductPricesForm.form.valid || product.importType !== 'manually'">CONFIRM</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
