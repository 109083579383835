import {
    Component, Input, OnInit
} from '@angular/core';

import {AllCommunityModules} from '@ag-grid-community/all-modules';

import {Business, Product, User} from '../../../../app/_models';
import {BusinessService, ProductService} from '../../../../app/_services';
import {Observable} from 'rxjs';
import {RouterLinkRendererComponent} from '../../../../app/_directives';

@Component({
    selector: 'app-list-business-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    entryComponents: [ RouterLinkRendererComponent ]
})

export class BusinessUsersComponent implements OnInit {
    defaultColDef = { resizable: true ,  sortable: true, filter: true};
    @Input('business') business: Business = {};
    columnDefs = [
        {headerName: 'Id', field: 'id',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/user'
            }},
        {field: 'email'},
        {field: 'role'},
        {field: 'created_at'},
        {field: 'updated_at'},
    ];

    modules = AllCommunityModules;
    rowData: Observable<User[]>;

    constructor(private businessService: BusinessService) {
    }

    ngOnInit() {
        if (this.business && this.business.id) {
            this.rowData = this.businessService.listUsers(this.business.id);
        }
    }

}
