<div class="page-content container-fluid">
    <h1 class="text-center page-title mb-5">Payment Details</h1>
    <div class="row justify-content-center">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="id">Id</label>
                        <input type="text" class="form-control" [value]="payment.id" name="id" id="id" readonly>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="businessId">businessId</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [value]="payment.businessId" name="businessId" id="businessId" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text input-group-text-primary"><a [routerLink]="'/business/'+payment.businessId" target="_blank"><i class="fas fa-external-link-alt"></i></a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="shopifyId">Luxify Order Id</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [value]="payment.shopifyId" name="shopifyId" id="shopifyId" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text input-group-text-primary"><a [routerLink]="'/orders/'+payment.shopifyId" target="_blank"><i class="fas fa-external-link-alt"></i></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="source">Source</label>
                        <input type="text" class="form-control" [value]="payment.source" name="source" id="source" readonly>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="sourceId">Source Order Id</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [value]="payment.sourceId" name="sourceId" id="sourceId" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text input-group-text-primary"><a [routerLink]="payment.orderURL" target="_blank"><i class="fas fa-external-link-alt"></i></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="col-form-label" for="amount">amount</label>
                        <div class="input-group">
                            <input type="number" class="form-control" [value]="payment.amount" name="amount" id="amount" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text input-group-text-primary">$AUD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="col-form-label" for="quantity">quantity</label>
                        <input type="text" class="form-control" [value]="payment.quantity" name="quantity" id="quantity" readonly>
                    </div>
                </div>

            </div>

            <hr/>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="charger_at">charger_at</label>
                        <input type="text" class="form-control" value="{{payment.charger_at | timeToLabel}}" name="charger_at" id="charger_at" readonly>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="transferred_at">transferred_at</label>
                        <input type="text" class="form-control" value="{{payment.transferred_at | timeToLabel}}" name="transferred_at" id="transferred_at" readonly>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="fulfilled_at">fulfilled_at</label>
                        <input type="text" class="form-control" value="{{payment.fulfilled_at | timeToLabel}}" name="fulfilled_at" id="fulfilled_at" readonly>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="refunded_at">refunded_at</label>
                        <input type="text" class="form-control" value="{{payment.refunded_at | timeToLabel}}" name="refunded_at" id="refunded_at" readonly>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="col-form-label" for="cancelled_at">cancelled_at</label>
                        <input type="text" class="form-control" value="{{payment.cancelled_at | timeToLabel}}" name="cancelled_at" id="cancelled_at" readonly>
                    </div>
                </div>
            </div>
            <hr/>
            <label>Products</label>
            <div class="row">
                <div class="col-sm-4" *ngFor="let product of payment.products; let i=index">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" [value]="product.id" [name]="'productId-'+i" [id]="'productId-'+i" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text input-group-text-primary"><a [routerLink]="'/product/' + payment.businessId + '/' + product.id" target="_blank"><i class="fas fa-external-link-alt"></i></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
