<div class="page-content container-fluid">
  <h1 class="page-title text-center mb-5">MY ACCOUNT</h1>
  <div class="row justify-content-center">
    <div class="col-lg-9">

      <div *ngIf="user">
        <h6 class="mb-5">IDENTIFICATION</h6>
        <div class="summary-view" *ngIf="!edit.identification">
          <p>Email: <span class="gray-color">{{user.email}}</span></p>
          <p>Password: <span class="gray-color">******</span></p>
          <div class="text-right">
            <button type="button" (click)="editBlock('identification')" class="btn btn-link primary-color">MODIFY
            </button>
          </div>
        </div>
        <div class="edit-view" *ngIf="edit.identification">
          <div class="form-group row">
            <label class="col-form-label col-sm-3" for="userEmail">Email:</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="userEmail" placeholder="Enter first name here"
                     [value]="user.email" name="firstNameUser" required readonly>
              <p class="primary-color small">(email address can only be changed by contacting our Customer
                Service)</p>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-sm-3">Password:</label>
            <div class="col-sm-9">
              <p class=" small"><span class="primary-color" (click)="resetPassword()" style="cursor: pointer;"><u>Reset my password</u></span>
                <span class="gray-color"> by sending me a link to reset it.</span></p>
            </div>
          </div>
        </div>
        <ng-template #alertMessage></ng-template>
        <hr/>
      </div>
    </div>
  </div>
</div>
