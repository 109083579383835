/**
 * @Usage : <tr *ngFor='let obj of objects | orderBy : ObjFieldName: OrderByType'>
 * @Param : ObjFieldName: object field name you want to sort;
 * @Param OrderByType: boolean; true: descending order; false: ascending;
 */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'durationToTime'
})
export class DurationToTimePipe implements PipeTransform {
  transform(value: number): string {
    const num = value || 0;
    const hours = (num / 60);
    let rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const rday = Math.floor(rhours / 24);
    if (rday > 0) {
      rhours = rhours % 24;
    }
    if (rhours && rminutes) {
      return (rday ? rday + ' jour' + (rday > 1 ? 's' : '') + ' et ' : '') + rhours + 'h' + rminutes;
    } else if (rhours && !rminutes) {
      return (rday ? rday + ' jour' + (rday > 1 ? 's' : '') + ' et ' : '') + rhours + ' heure' + (rhours > 1 ? 's' : '');
    } else {
      return (rday ? rday + ' jour' + (rday > 1 ? 's' : '') + ' et ' : '') + rminutes + ' minute' + (rminutes > 1 ? 's' : '');
    }
  }
}

import * as moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

@Pipe({
  name: 'timeUntilNow'
})
export class TimeUntilNowPipe implements PipeTransform {
  transform(date: any): string {
    const now = moment();
    let pastDate;
    if (date.seconds) {
      pastDate = moment.unix(date.seconds);
    } else {
      pastDate = moment(date);
    }
    if (now.diff(pastDate, 'years')) {
      const years = now.diff(pastDate, 'years');
      return years + ' year' + (years > 1 ? 's' : '') + ' ago';
    } else if (now.diff(pastDate, 'months')) {
      const months = now.diff(pastDate, 'months');
      return months + ' month' + (months > 1 ? 's' : '') + ' ago';
    } else if (now.diff(pastDate, 'weeks')) {
      const weeks = now.diff(pastDate, 'weeks');
      return weeks + ' week' + (weeks > 1 ? 's' : '') + ' ago';
    } else if (now.diff(pastDate, 'days')) {
      const days = now.diff(pastDate, 'days');
      return days + ' day' + (days > 1 ? 's' : '') + ' ago';
    } else if (now.diff(pastDate, 'hours')) {
      const hours = now.diff(pastDate, 'hours');
      return hours + ' hour' + (hours > 1 ? 's' : '') + ' ago';
    } else if (now.diff(pastDate, 'minutes')) {
      const minutes = now.diff(pastDate, 'minutes');
      return minutes + ' minute' + (minutes > 1 ? 's' : '') + ' ago';
    } else if (now.diff(pastDate, 'seconds')) {
      const seconds = now.diff(pastDate, 'seconds');
      return seconds + ' second' + (seconds > 1 ? 's' : '') + ' ago';
    } else {
      return 'Date invalid!';
    }
  }
}


@Pipe({
  name: 'timeToLabel'
})
export class TimeToLabelPipe implements PipeTransform {
  transform(date: any, format?: string): string {
    let tempDate;
    if (!date) {
      return null;
    } else if (date.seconds) {
      tempDate = moment.unix(date.seconds);
    } else if (date > 0) {
      tempDate = moment.unix(date);
    } else if (date && date.day && date.month && date.year) {
      tempDate = moment(`${date.day}/${date.month}/${date.year}`, 'DD/MM/YYYY');
    } else {
      tempDate = moment(date);
    }
    if (format) {
      return tempDate.format(format);
    } else {
      return tempDate.format('dddd DD/MM/YYYY H:mm');

    }
  }
}


