import {
    Component, ComponentFactoryResolver, ViewChild, ViewContainerRef,
} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AlertService, AppService, BusinessService, ProductService} from '../../../app/_services';
import {Business, Product, Variant} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertComponent} from '../../../app/_directives';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],

})
export class ProductComponent {
    @ViewChild('inventoryMessage', { read: ViewContainerRef }) inventoryMessage: ViewContainerRef;
    shopifyAdminUrl = environment.shopifyAdminUrl;
    currentBusiness: Business = {};
    currentProduct: Product = {
        importType: 'manually',
        isShippingFromBusiness: true
    };
    defaultVariant: Variant = {};

    isValid = {
        details: false,
        medias: false,
        characteristics: false,
        prices: false,
        seller: false,
    };
    show = {
        details: true,
        medias: false,
        characteristics: false,
        prices: false,
        seller: false,
    };

    listingProductDatas: any = this.appService.getListingProductDatas();
    realProductDatas: any = this.appService.realStructure();
    photosList: any[] = [];
    countries: any[] = this.appService.countryList();
    currencies: any[] = this.appService.currenciesList();
    materialsList: any[] = this.appService.materialsList();
    colorsList: any[] = this.appService.colorsList();
    years: number[] = [];
    fileUploadFolder: string;
    firstLoad = true;

    isLoading = false;

    constructor(private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private appService: AppService,
                private businessService: BusinessService,
                private cfr: ComponentFactoryResolver,
                private alertService: AlertService,
                private productService: ProductService) {
        this.route.params.subscribe(params => {
            if (params.businessId) {
                this.getBusiness(params.businessId, params.id);
                const dt = new Date();
                for (let i: number = dt.getFullYear(); i > 1900; i--) {
                    this.years.push(i);
                }
            } else {
                this.router.navigate(['404']).then();
                this.loaderService.hide();
            }
        });
    }

    getBusiness(businessId, productId) {
        this.loaderService.show();
        this.businessService.get(businessId)
            .subscribe((business: Business) => {
                this.currentBusiness = business;
                if (!productId) {
                    this.fileUploadFolder = `images/products/${businessId}`;
                    this.checkProductObject();
                    this.loaderService.hide();
                } else {
                    this.fileUploadFolder = `images/products/${businessId}/${productId}`;
                    this.getProduct(productId);
                }
            }, () => {
                this.router.navigate(['404']).then();
                this.loaderService.hide();
            });
    }

    getProduct(productId) {
        this.productService.get(productId).subscribe((res: Product) => {
            this.currentProduct = res;
            if (res.category && this.listingProductDatas[res.category]) {
                this.photosList = this.listingProductDatas[res.category].photos;
            }
            if (this.firstLoad) {
                this.checkProductObject();
                this.firstLoad = false;
                this.switchView('details');
            }
            this.loaderService.hide();
        }, () => {
            this.router.navigate(['404']).then();
            this.loaderService.hide();
        });
    }

    checkProductObject() {
        // test Object structure
        this.defaultVariant =
            this.currentProduct.variants && this.currentProduct.variants.length > 0 ? this.currentProduct.variants[0] : {};
        if (!this.currentProduct.images) {
            this.currentProduct.images = [];
        }
        if (!this.defaultVariant.length_unit) {
            this.defaultVariant.length_unit = null;
        }
        if (!this.defaultVariant.height_unit) {
            this.defaultVariant.height_unit = null;
        }
        if (!this.defaultVariant.width_unit) {
            this.defaultVariant.width_unit = null;
        }
    }

    switchView(step) {
        this.show = {
            details: false,
            medias: false,
            characteristics: false,
            prices: false,
            seller: false,
        };
        this.show[step] = true;

        // test steps validity
        this.isValid.details = !!(this.currentProduct.brand && this.currentProduct.subCategory && this.currentProduct.model
            && this.defaultVariant.material && this.defaultVariant.color);
        this.isValid.medias = true;
        this.photosList.forEach((value, index) => {
            if (!this.currentProduct.images[index]) {
                this.currentProduct.images[index] = {};
                this.isValid.medias = false;
            } else if (!this.currentProduct.images[index].src) {
                this.isValid.medias = false;
            }
        });
        this.isValid.characteristics = !!(this.currentProduct.bodyHtml && this.defaultVariant.serialNumber);
        this.isValid.prices = !!(this.defaultVariant.condition && this.defaultVariant.price && this.currentProduct.currency);
    }

    stepReturn(result: { step: string, product: Product }) {
        if (result.step === 'medias' && result.product.category) {
            this.photosList = this.listingProductDatas[result.product.category].photos;
        }
        if (result.product) {
            this.currentProduct = result.product;
            this.defaultVariant = result.product.variants[0];
        }
        if (result.step) {
            this.switchView(result.step);
        }
        window.scrollTo(0, 0);
    }

    validateProduct() {
        this.productService.update({id: this.currentProduct.id, validated: true})
            .then(() => {
                this.isLoading = false;
                this.loaderService.hide();
            });
    }

    certifyProduct() {
        this.productService.update({id: this.currentProduct.id, certificated: true})
            .then(() => {
                this.isLoading = false;
                this.loaderService.hide();
            });
    }
    updateInventoryQuantity() {
        this.loaderService.show();
        this.productService.update({id: this.currentProduct.id, variants: [this.defaultVariant]})
            .then(() => {
                this.showAlert('inventoryMessage');
                this.alertService.success('Inventory quantity updated!');
                this.isLoading = false;
                this.loaderService.hide();
            });
    }

    unpublishedProduct() {
        this.productService.update({id: this.currentProduct.id, published: false})
            .then(() => {
                this.isLoading = false;
                this.loaderService.hide();
            });
    }
    showAlert(target) {
        this[target].clear();
        const factory = this.cfr.resolveComponentFactory(AlertComponent);
        const ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
}
