import {
    Component, OnInit
} from '@angular/core';

import {AllCommunityModules, GridApi, IDatasource, IGetRowsParams} from '@ag-grid-community/all-modules';

import {BusinessOrder} from '../../../../app/_models';
import {AdminService, PaymentService} from '../../../../app/_services';
import {Observable} from 'rxjs';
import {RouterLinkRendererComponent} from '../../../../app/_directives';
import {TimeToLabelPipe} from '../../../../app/_pipe';

@Component({
    selector: 'app-list-business-orders',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    entryComponents: [ RouterLinkRendererComponent ],
    providers: [ TimeToLabelPipe ]
})

export class ListBusinessOrdersComponent implements OnInit {
    defaultColDef = { resizable: true ,  sortable: true, filter: true};
    columnDefs = [
        {headerName: 'Id', field: 'id',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/business-order',
            }},
        {headerName: 'Business', field: 'businessId',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/business',
            }},
        {headerName: 'Shopify Order', field: 'shopifyId',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/order',
            }},
        {field: 'charged_at', valueFormatter: this.timeToLabel.transform},
        {field: 'source'},
        {field: 'isFulfilled'},
        {field: 'fulfilled_at', valueFormatter: this.timeToLabel.transform},
        {field: 'quantity'},
        {field: 'amount'},
        {field: 'totalDiscounts'},
        {field: 'transferredAmount'},
        {field: 'serviceFee'},
        {field: 'currency'},
        {field: 'customerName'},
    ];

    modules = AllCommunityModules;
    isFirstLoad = true;
    totalRecords = 0;
    gridApi: GridApi;
    maxSize = 100;
    gridOptions = {
        pagination: true,
        rowModelType: 'infinite',
        cacheBlockSize: this.maxSize, // you can have your custom page size
        paginationPageSize: this.maxSize // pagesize
    };

    dataSource: IDatasource = {
        getRows: (params: IGetRowsParams) => {
            let dataPromise: Promise<any[]>;
            if (this.isFirstLoad) {
                dataPromise = this.adminService.getFirstData('businessOrders', 'charger_at', this.maxSize);
                this.isFirstLoad = false;
            } else {
                dataPromise = this.adminService.getNextData();
            }
            dataPromise.then(data => {
                const size = data.length;
                this.totalRecords += size;
                let pagCount = this.totalRecords;
                if (size === this.maxSize) {
                    pagCount += this.maxSize * 2;
                }
                params.successCallback(
                    data,
                    pagCount
                );
            });
        }
    };
    constructor(private adminService: AdminService, private timeToLabel: TimeToLabelPipe) {
    }

    ngOnInit() {
    }
    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.setDatasource(this.dataSource);
    }
}
