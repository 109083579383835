import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
    template: '<a [routerLink]="[params.inRouterLink]"  (click)="navigate(params.inRouterLink)">{{params.cellContent || params.value}}</a>'
})
export class RouterLinkRendererComponent implements AgRendererComponent {
    params: any;

    constructor(
        private ngZone: NgZone,
        private router: Router) { }

    agInit(params: any): void {
        this.params = params;

        if (params.isProductFromPayment && params.data) {
            this.params.inRouterLink +=  `/${params.data.businessId}/${params.data.product.id}`;
            return;
        }
        if (params.slug2 && params.data) {
            this.params.inRouterLink +=  `/${params.data[params.slug2]}`;
            if (params.slug3) {
                this.params.inRouterLink +=  `/${params.data[params.slug3]}`;
            }
        }
        this.params.inRouterLink +=  `/${params.value}`;

    }

    refresh(params: any): boolean {
        return false;
    }

    // This was needed to make the link work correctly
    navigate(link) {
        this.ngZone.run(() => {
            this.router.navigate([link]);
        });
    }
}
