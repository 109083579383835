import {Component, HostListener} from '@angular/core';
import {User} from '../../_models';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../../_services';
import {Location} from '@angular/common';
import {LoaderService} from '../loader/loader.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent {

  user: User;
  isLoading = false;
  isHidden = false;
  isTransparent = false;

  constructor(private router: Router,
              private location: Location,
              private authService: AuthenticationService,
              private loaderService: LoaderService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt.url.indexOf('/login') === 0) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }
    });

    const localData = localStorage.getItem('currentUser');
    this.user = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
  }

  logout() {
    this.loaderService.show();
    this.isLoading = true;
    this.authService.logout()
        .then(
            () => {
              return this.router.navigate(['/']).then(() => {
                location.reload();
                this.isLoading = false;
              });
            },
            error => {
              console.error(error);
              this.isLoading = false;
              this.loaderService.hide();
            });
  }
}
