import {
    Component,
} from '@angular/core';

import {PaymentService} from '../../../../app/_services';
import {LoaderService} from '../../../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-luxify-order',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],

})
export class LuxifyOrderDetailsComponent {
    order: any = {};
    isLoading = false;

    constructor(private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private paymentService: PaymentService) {


        this.getOrder();
    }

    getOrder() {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.paymentService.getLuxifyOrder(params.id).subscribe((res: any) => {
                    if (!res) {
                        this.loaderService.hide();
                        return this.router.navigate(['404']).then();
                    }
                    this.order = res;
                    this.loaderService.hide();
                }, () => {
                    this.router.navigate(['404']).then();
                    this.loaderService.hide();
                });
            } else {
                this.router.navigate(['404']).then();
                this.loaderService.hide();
            }
        });
    }
}
