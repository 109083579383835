import {
  Component, ViewChild, ViewContainerRef, ComponentFactoryResolver
} from '@angular/core';

import {User} from '../../app/_models';
import {AlertService, AuthenticationService} from '../../app/_services';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {AlertComponent} from '../../app/_directives';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent {
  user: User;
  model: any = {};

  show = {
    signIn: true,
    forgotPass: false,
    resendVerifiedEmail: false
  };

  isLoading = false;
  @ViewChild('alertSignin',
      {read: ViewContainerRef}) alertSignin: ViewContainerRef;
  @ViewChild('alertForgotPass',
      {read: ViewContainerRef}) alertForgotPass: ViewContainerRef;

  constructor(private authService: AuthenticationService,
              private alertService: AlertService,
              private cfr: ComponentFactoryResolver,
              private loaderService: LoaderService) {

  }

  showLoginTab(tab) {
    this.show.forgotPass = false;
    this.show.signIn = false;
    this.show[tab] = true;
  }

  resendVerifiedEmail() {
    this.isLoading = true;
    this.loaderService.show();

    this.authService.sendEmailVerification(this.model.email).then(
        () => {
          this.showAlert('alertSignin');
          this.alertService.success('The verification email has been successful send!');

          this.show.resendVerifiedEmail = false;
          this.isLoading = false;
          this.loaderService.hide();
        },
        error => {
          this.showAlert('alertSignin');
          this.alertService.error(error.message);
          this.isLoading = false;
          this.loaderService.hide();
        });
  }

  signin() {
    this.isLoading = true;
    this.loaderService.show();
    this.authService.login(this.model.email, this.model.password).then(
        () => {
          $('#SignUpModal').modal('hide');
          location.reload();
          this.isLoading = false;
          this.loaderService.hide();
        },
        error => {
          this.showAlert('alertSignin');
          if (error.code === 'auth/email-not-verified') {
            this.show.resendVerifiedEmail = true;
          }
          this.alertService.error(error.message);
          this.isLoading = false;
          this.loaderService.hide();
        });
  }

  loginGoogleUser() {
    this.isLoading = true;
    this.loaderService.show();
    this.authService.loginGoogleUser().then(
        () => {
          $('#SignUpModal').modal('hide');
          location.reload();
          this.isLoading = false;
          this.loaderService.hide();
        },
        error => {
          this.showAlert('alertSignin');
          this.alertService.error(error);
          this.isLoading = false;
          this.loaderService.hide();
        });
  }

  forgotPass() {
    this.isLoading = true;
    this.authService.forgotPassword(this.model.email).then(
        () => {
          this.showAlert('alertSignin');
          this.alertService.success(
              'Password recovery instruction has been sent to your email.',
              true);
          this.isLoading = false;
          this.model = {};
        },
        error => {
          this.showAlert('alertForgotPass');
          this.alertService.error(error);
          this.isLoading = false;
        });
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
