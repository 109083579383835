import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}
  private checkUser(): Promise<any> {
    return new Promise((resolve) => {
      this.auth.isLogin()
        .then((user: any): any => {
          if (user && user.id) {
            return this.auth.pullUserData(user.id).then((res: any) => {
              if (res) {
                localStorage.setItem('currentUser', JSON.stringify(res.user));
              }
              resolve(true);
            });
          } else {
            return this.auth.logout().then(() => {
              resolve(false);
            });
          }
        });
    });
  }
  canActivate(): any {
    return new Promise((resolve) => {
      return this.checkUser()
        .then((res: any) => {
          if (!res) {
            return this.router.navigate(['/login']).then(() => {
              resolve(false);
            });
          } else {
             resolve(true);
          }
        });
    });
  }
}
