import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Business, Product, User} from '../_models/';

import {environment} from '../../environments/environment';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as moment from 'moment';
import {AngularFirestore} from '@angular/fire/firestore';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }).append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*')
};

@Injectable()
export class BusinessService {
  constructor(private http: HttpClient, private db: AngularFirestore) {}


  list(): Observable<Business[]> {
    return this.db.collection('businesses', ref => {
      let query: any = ref;
      query = query.orderBy('created_at');
      return query;
    }).snapshotChanges().pipe(map((actions: any) => {
      return actions.map((a: any) => {
        const data = a.payload.doc.data() as Business;
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  get(id: string): Observable<Business> {
    return this.db.collection('businesses').doc(id)
        .snapshotChanges().pipe(
            map(a => {
              const data = a.payload.data() as Business;
              data.id = a.payload.id;
              return data;
            }),
            catchError(this.handleError<Business>(`getBusinesses id=${id}`))
        );
  }

  create(business: Business) {
    business.created_at = moment().format();
    const businessRef = this.db.firestore.collection('businesses').doc();
    return businessRef.set(business, {merge: true}).then(() => {
      return business;
    });
  }

  update(business: Business): Promise<Business> {
    const newBusiness: Business =  {
      businessType: business.businessType || null,
      hasPaymentDetails: business.hasPaymentDetails || false,
      name: business.name || null,
      phone: business.phone || null,
      profileImage: business.profileImage || null,
      description: business.description || null,
      taxId: business.taxId || null,
      facebookPage: business.facebookPage || null,
      instagramPage: business.instagramPage || null,
      linkedinPage: business.linkedinPage || null,
      videoUrl: business.videoUrl || null,
      address: business.address || null,
      email: business.email || null,
      hasAgreeToTerms: business.hasAgreeToTerms || false,
      hearAboutUs: business.hearAboutUs || null,
      representativeEmail: business.representativeEmail || null,
      shippingRates: business.shippingRates || [],
      representativePhone: business.representativePhone || null,
      representativeFirstName: business.representativeFirstName || null,
      representativeLastName: business.representativeLastName || null,
      representativeDateOfBirth: business.representativeDateOfBirth || null,
      representativeIdentityProof: business.representativeIdentityProof || null,
    };
    const businessDoc = this.db.collection('businesses').doc(business.id);
    return businessDoc.update(newBusiness).then(() => {
      return business;
    });
  }

  updateField(business: Business, fieldName: string, data: any): Promise<void> {
    const businessDoc = this.db.collection('businesses').doc(business.id);
    const newBusiness: any = {};
    newBusiness[fieldName] = data || null;
    return businessDoc.update(newBusiness);
  }

  listSource(businessId: string): Observable<any[]> {
    return this.db.collection('businesses').doc(businessId).collection('sources')
        .snapshotChanges().pipe(map((actions: any) => {
          return actions.map((a: any) => {
            const data = a.payload.doc.data() as Business;
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        }));
  }

  listUsers(businessId: string): Observable<User[]> {
    return this.db.collection('businesses').doc(businessId).collection('users')
        .snapshotChanges().pipe(map((actions: any) => {
          return actions.map((a: any) => {
            const data = a.payload.doc.data() as Business;
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        }));
  }

  linkUser(data: any): Observable<any> {
    return this.http.post(environment.cloudFunctionUrl + '/linkUserToBusiness', JSON.stringify({data}), httpOptions)
        .pipe(
            map((res: any) => {
              if (res.success) {
                return res;
              } else {
                throw res;
              }
            })
        );
  }

  removeUser(data: any): Observable<any> {
    return this.http.post(environment.cloudFunctionUrl + '/removeUserToBusiness', JSON.stringify({data}), httpOptions)
        .pipe(
            map((res: any) => {
              if (res.success) {
                return res;
              } else {
                throw res;
              }
            })
        );
  }

  updateUserRole(data: any): Observable<any> {
    return this.http.post(environment.cloudFunctionUrl + '/updateUserToBusiness', JSON.stringify({data}), httpOptions)
        .pipe(
            map((res: any) => {
              if (res.success) {
                return res;
              } else {
                throw res;
              }
            })
        );
  }

  validInvitationRequest(data: any): Observable<any> {
    return this.http.post(environment.cloudFunctionUrl + '/validInvitationRequest', JSON.stringify({data}), httpOptions)
        .pipe(
            map((res: any) => {
              if (res.success) {
                return res;
              } else {
                throw res;
              }
            })
        );
  }



  deleteUser(businessId: string, user: User): Promise<void> {
    const batch = this.db.firestore.batch();

    const businessUserRef = this.db.firestore
        .collection('businesses').doc(businessId)
        .collection('users').doc(user.id);
    batch.delete(businessUserRef);
    const userRef = this.db.firestore.collection('users').doc(user.id);
    batch.update(userRef, {businessId: firebase.firestore.FieldValue.delete(), role: firebase.firestore.FieldValue.delete()});
    return batch.commit();
  }

  getPayoutDetails(id: string): Observable<Business> {
    return this.db.doc('businesses/' + id + '/payout/' + id)
        .snapshotChanges().pipe(
            map((a: any) => {
              const data = a.payload.data() as Business;
              if (data) {
                data.id = a.payload.id;
              }
              return data;
            }),
            catchError(this.handleError<Business>(`getPayout id=${id}`))
        );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
