import {
    Component, Input, OnInit
} from '@angular/core';

import {AllCommunityModules} from '@ag-grid-community/all-modules';

import {Business, Product} from '../../../../app/_models';
import {ProductService} from '../../../../app/_services';
import {Observable} from 'rxjs';
import {RouterLinkRendererComponent} from '../../../../app/_directives';

@Component({
    selector: 'app-list-business-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    entryComponents: [ RouterLinkRendererComponent ]
})

export class BusinessProductsComponent implements OnInit {
    defaultColDef = { resizable: true ,  sortable: true, filter: true};
    @Input('business') business: Business = {};
    columnDefs = [
        {headerName: 'Id', field: 'id',
            cellRendererFramework: RouterLinkRendererComponent,
            cellRendererParams: {
                inRouterLink: '/product',
                slug2: 'businessId',
            }},
        {field: 'importType'},
        {field: 'validated'},
        {field: 'published'},
        {field: 'published_at'},
        {field: 'available'},
        {field: 'isRemoved'},
        {field: 'year'},
        {field: 'category'},
        {field: 'subCategory'},
        {field: 'brand'},
        {field: 'model'},
        {field: 'isVintage'},
        {field: 'currency'},
        {field: 'hasAgreeToTerms'},
        {field: 'isShippingFromBusiness'},
        {field: 'created_at'},
        {field: 'updated_at'},
    ];

    modules = AllCommunityModules;
    rowData: Observable<Product[]>;

    constructor(private productService: ProductService) {
    }

    ngOnInit() {
        if (this.business && this.business.id) {

            this.rowData = this.productService.listForBusiness(this.business.id);
        }
    }

}
